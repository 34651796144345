<template>
	<div class="hide-scrollbar" style="width:100%; background-color: #F7F5F9; overflow: hidden;">
		<FormModal />
		<a-modal :visible="fieldSelect" :footer="false" @cancel="onClose" @ok="onClose" :width="'650px'"
			:bodyStyle="{ padding: '20px' }" centered>

			<template v-if="fieldSelect">
				<div class="dF mb-5">
					<h4 class="mr-3" style="margin-bottom: 0;">Fields</h4>
					<div style="color: #9EA0A5; font-size: 12px; margin-top: 4px;">Select a field you want to add</div>
				</div>

				<!-- All Fields -->
				<a-radio-group v-model="selectedField" style="width: 100%;">

					<!-- Header Fields -->
					<h6>Header Fields</h6>
					<a-row :gutter="16">
						<a-col class="mb-3" :span="8" v-for="(custom) in headerChoices" :key="custom.type">
							<a-radio-button class="fieldOptions" :value="custom.type">
								<div class="dF aC">
									<span v-html="custom.icon" v-if="custom.icon" />
									<span class="pb-2 ml-2" style="font-size: 14px;">
										{{ custom.label }}
									</span>
								</div>
							</a-radio-button>
						</a-col>
					</a-row>

					<!-- Custom Fields -->
					<h6 style="margin-top: 35px;">Custom Fields</h6>
					<a-row :gutter="10">
						<a-col class="mb-3" :span="8" v-for="(custom) in customFieldChoices" :key="custom.type">
							<a-radio-button v-if="custom.type != 'child'" class="fieldOptions" :value="custom.type">
								<div class="dF aC">
									<span v-html="custom.icon" v-if="custom.icon" />
									<span class="pb-2 ml-2" style="font-size: 14px;">
										{{ custom.label }}
									</span>
								</div>
							</a-radio-button>
							<a-radio-button v-else-if="custom.type == 'child' && childInstances && childInstances.length"
								class="fieldOptions" :value="custom.type">
								<div class="dF aC">
									<span v-html="custom.icon" v-if="custom.icon" />
									<span class="pb-2 ml-2" style="font-size: 14px;">
										{{ custom.label }}
									</span>
								</div>
							</a-radio-button>
						</a-col>
					</a-row>

				</a-radio-group>

				<div class="mt-4" style="width: 100%; text-align: right;">
					<a-button @click="onClose" class="mr-3 cancel-button" size="large">CANCEL</a-button>
					<a-button size="large" @click="submitField" :disabled="selectedField === ''"
						type="primary">CREATE</a-button>
				</div>
			</template>
		</a-modal>

		<div class="dF jSB aC">
			<div @click="goBack()" class="dF mb-3 mt-3" style="margin-left: 15px; cursor: pointer;">
				<div v-if="formType == 'Add New Form' || formType == 'Edit Form'">
					<a-icon class="mr-3" style="font-size: 1.5rem;" type="arrow-left" />
				</div>
				<h4 v-if="$route.params.id">{{ form.type == 'survey' ? 'Edit Survey' : 'Edit Form' }}</h4>
				<h4 v-else>{{ form.type == 'survey' ? 'Add New Survey' : 'Add New Form' }}</h4>
			</div>
			<!-- <div v-clipboard:copy="`https://forms.bildhive.app/${form.id}`" @click="() => $message.success('Link Copied')"
				v-if="formType == 'Edit Form' && form.type == 'walk-in'" class="dF aC"
				style="padding:0 1.33rem; cursor:pointer; color:var(--orange)">
				<a-icon class="mr-2" type="link" />
				<div>{{ `Form URL: https://forms.bildhive.app/${form.id}` }}</div>
			</div> -->
		</div>

		<!-- ADD/EDIT NEW FORM -->
		<div class="contacts-page container dF"
			style="height: calc(100% - 80px); max-width: calc(100% - 15px); padding-right: 0; padding-left: 0; margin-right: 15px; margin-left: 15px;">
			<BHLoading :show="loadingPage" />
			<div class="custom-scrollbar w-full h-full" style="overflow-y: scroll;">
				<div class="responsiveFlex">

					<!-- Left side of form -->
					<div class="leftSide">

						<!-- General Information -->
						<div>
							<a-card class="mb-3" title="General Information">
								<a-row :gutter="16">
									<a-col :span="12">
										<a-form-item label="Title">
											<a-input v-model="form.name" placeholder="Enter form title" size="large" />
										</a-form-item>
									</a-col>
									<a-col :span="12">
										<a-form-item label="Group">
											<a-select v-model="form.group" mode="multiple" style="width: 100%" placeholder="Select Form Group" size="large" @change="selectedGroup" @search="handleGroupSearch" :filter-option="false">
												<a-select-option v-for="group in filteredGroups" :value="group.id" :key="`SDFS${group.id}`">
													{{group.name}}
												</a-select-option>
											</a-select>
										</a-form-item>
									</a-col>
								</a-row>
							</a-card>
							<!-- Right side of form -->
							<div v-if="formLoaded && form.type !== 'worksheet' && currentWidth <= 1250" class="rightSide rightForm">
								<a-card class="mb-3 tags" title="Apply Tags to Registrants">
									<a-tooltip slot="extra" overlayClassName="change-tooltip-color">
										<template slot="title">
											These tags will be applied to Registrants as they enter through this form.
										</template>
										<a-icon type="question-circle"
											style="line-height: 25px; margin-top: 5px; color: var(--orange);" />
									</a-tooltip>
									<a-select v-model="form.tags" mode="tags" style="width: 100%"
										placeholder="Interested Buyer/Senior/Have Pets..." size="large">
										<a-select-option v-for="tag in tags" :value="tag.name" :key="tag.id">
											{{ tag.name }}
										</a-select-option>
									</a-select>
								</a-card>
								<a-card class="mb-3 autoresponder" title="Autoresponder">
									<a-tooltip slot="extra" overlayClassName="change-tooltip-color">
										<template slot="title">
											This will be the autoresponder that users receive after submitting this form.
										</template>
										<a-icon type="question-circle"
											style="line-height: 25px; margin-top: 5px; color: var(--orange);" />
									</a-tooltip>
									<a-form-item label="Autoresponder Subject Line">
										<a-input size="large" v-model="form.options.autoresponderSubject"
											placeholder="Subject Line" />
									</a-form-item>
									<a-form-item label="Autoresponder Preheader Line">
										<a-input size="large" v-model="form.options.autoresponderPreheader"
											placeholder="Preheader Line" />
									</a-form-item>
									<a-form-item label="Select an Autoresponder Template">
										<a-select v-model="form.options.autoresponder" size="large" style="width: 100%">
											<a-select-option value="">
												None
											</a-select-option>
											<a-select-option v-for="(temp, tempI) in templates" :value="temp.id"
												:key="temp + tempI">
												{{ temp.name }}
											</a-select-option>
										</a-select>
									</a-form-item>
									<a-tooltip overlayClassName="change-tooltip-color">
										<template slot="title">
											The Sender Email Address identifies the address where the email is coming from.
											To add more addresses, go to <span><a
													:href="`https://email.bildhive.${$tld}/settings`" target="__blank"
													style="color: var(--orange);">Broadcast Email Builder</a></span> app.
										</template>
										<a-icon type="question-circle"
											style="line-height: 25px; position: absolute; margin-top: 1px; left: 210px; z-index: 2; color: var(--orange);" />
									</a-tooltip>
									<a-form-item label="Select a Sender Email Address">
										<a-select v-model="form.options.fromEmail" size="large" style="width: 100%">
											<a-select-option value="">
												Default (no-reply@mail.bildhive.com)
											</a-select-option>
											<a-select-option v-for="(email, emailI) in senders" :value="email.email"
												:key="email + emailI">
												{{ email.email }}
											</a-select-option>
										</a-select>
									</a-form-item>
								</a-card>
								<a-card class="mb-3 notification" title="Submission Notification">
									<a-tooltip slot="extra" overlayClassName="change-tooltip-color">
										<template slot="title">
											This will send an Email/Notifications to the following users of New Registrants.
										</template>
										<a-icon type="question-circle"
											style="line-height: 25px; margin-top: 5px; color: var(--orange);" />
									</a-tooltip>
									<a-select mode="multiple" v-model="form.users" style="width: 100%" size="large"
										placeholder="Select Users">
										<a-select-option v-for="user in users" :value="user.id"
											:key="`SDFSXXXXXX${user.id}`">
											{{ user.firstName }} {{ user.lastName }} <span
												style="font-style: italic;">{{ '(' + user.email + ')' }}</span>
										</a-select-option>
									</a-select>
								</a-card>
								<a-card class="mb-3" title="Submit Button">
									Submit Button Label
									<a-input v-model="form.options.submitLabel" size="large" class="mt-2"
										placeholder="Submit">
									</a-input>
								</a-card>
								<a-card class="mb-3 thankyou" title="Thank You Message">
									<a-tooltip slot="extra" overlayClassName="change-tooltip-color">
										<template slot="title">
											The message that shows up after the registrant submits the form.
										</template>
										<a-icon type="question-circle"
											style="line-height: 25px; margin-top: 5px; color: var(--orange);" />
									</a-tooltip>
									Headline
									<a-input v-model="form.options.thanks.headline" size="large" class="mt-2 mb-4"
										placeholder="Thank you for...">
									</a-input>
									Text
									<a-textarea v-model="form.options.thanks.text" :auto-size="{ minRows: 5, maxRows: 10 }"
										class="mt-2" />
								</a-card>
								<a-card class="mb-3" title="Consent">
									<div class="dF aC">
										Enabled?
										<a-switch class="ml-3" v-model="form.options.consent.enabled" />
									</div>
									<a-textarea v-model="form.options.consent.text" :auto-size="{ minRows: 3, maxRows: 10 }"
										class="mt-3" :disabled="!form.options.consent.enabled" />
								</a-card>
								<a-card class="mb-3" title="SMS Consent">
									<div class="dF aC">
										Enabled?
										<a-switch class="ml-3" v-model="form.options.smsConsent.enabled" />
									</div>
									<a-textarea v-model="form.options.smsConsent.text" :auto-size="{ minRows: 3, maxRows: 10 }"
										class="mt-3" :disabled="!form.options.smsConsent.enabled" />
								</a-card>
							</div>

							<div v-else-if="currentWidth <= 1250" class="rightSide rightForm">
								<a-card class="mb-3 tags" title="Apply Tags to Worksheet">
									<a-tooltip slot="extra" overlayClassName="change-tooltip-color">
										<template slot="title">
											These tags will be applied to Worksheet as they enter through this form.
										</template>
										<a-icon type="question-circle"
											style="line-height: 25px; margin-top: 5px; color: var(--orange);" />
									</a-tooltip>
									<a-select v-model="form.tags" mode="tags" style="width: 100%"
										placeholder="Interested Buyer/Senior/Have Pets..." size="large">
										<a-select-option v-for="tag in tags" :value="tag.name" :key="tag.id">
											{{ tag.name }}
										</a-select-option>
									</a-select>
								</a-card>
								<a-card class="mb-3 realtors" title="Worksheet Realtors">
									<a-tooltip slot="extra" overlayClassName="change-tooltip-color">
										<template slot="title">
											This worksheet will be used when specified Realtor fill up the worksheet.
										</template>
										<a-icon type="question-circle"
											style="line-height: 25px; margin-top: 5px; color: var(--orange);" />
									</a-tooltip>
									<a-select mode="multiple" v-model="form.options.serveAgents" style="width: 100%"
										size="large" placeholder="Select Users" @change="onRealtorChange">
										<a-select-option value="all">
											All Realtors (Default)
										</a-select-option>
										<a-select-option v-for="user in realtors" :value="user.id" :key="user.id">
											{{ user.firstName }} {{ user.lastName }} <span
												style="font-style: italic;">{{ '(' + user.email + ')' }}</span>
										</a-select-option>
									</a-select>
								</a-card>
								<a-card class="mb-3 notification" title="Submission Notification">
									<a-tooltip slot="extra" overlayClassName="change-tooltip-color">
										<template slot="title">
											This will send an Notifications to the following users of New Worksheet
											Submission.
										</template>
										<a-icon type="question-circle"
											style="line-height: 25px; margin-top: 5px; color: var(--orange);" />
									</a-tooltip>
									<a-select mode="multiple" v-model="form.users" style="width: 100%" size="large"
										placeholder="Select Users">
										<a-select-option v-for="user in users" :value="user.id" :key="user.id">
											{{ user.firstName }} {{ user.lastName }} <span
												style="font-style: italic;">{{ '(' + user.email + ')' }}</span>
										</a-select-option>
									</a-select>
								</a-card>
							</div>
						</div>

						<!-- Fields -->
						<a-card class="mb-3" title="Fields">
							<draggable :list="form.fields" @end="endMove" :delay-on-touch-only="true" :delay="100">
								<template v-for="(field, fieldI) in form.fields">

									<!-- Email Field -->
									<template v-if="field.type === 'email'">
										<a-card :style="rightBar == field.id ? 'border-color:var(--orange)' : ''"
											style="box-shadow: none;" :class="rightBar == field.id ? 'field-selected' : ''"
											class="mb-4 fieldCard" :key="field + fieldI" @mouseover="fieldHovering = fieldI"
											@mouseleave="fieldHovering = ''">
											<div v-show="fieldHovering === fieldI" class="dF"
												style="position: absolute; right: 24px; z-index: 1000;">
												<svg @click="editField(field, fieldI)" xmlns="http://www.w3.org/2000/svg"
													width="15" height="15" viewBox="0 0 24 24" fill="none"
													stroke="currentColor" stroke-width="2" stroke-linecap="round"
													stroke-linejoin="round" class="feather feather-edit-2 edit-delete">
													<path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
													</path>
												</svg>
											</div>
											<a-form-item :label="field.label || ' '" required>
												<a-input style="pointer-events: none;" size="large"
													:placeholder="field.placeholder">
												</a-input>
											</a-form-item>
										</a-card>
									</template>

									<!-- Name Field -->
									<template v-else-if="field.type === 'name'">
										<a-card :style="rightBar == field.id ? 'border-color:var(--orange)' : ''"
											:class="rightBar == field.id ? 'field-selected' : ''" class="mb-4 fieldCard"
											:key="field + fieldI" @mouseover="fieldHovering = fieldI"
											@mouseleave="fieldHovering = ''">
											<div v-show="fieldHovering === fieldI" class="dF aS"
												style="position: absolute; right: 24px; z-index: 1000;">
												<a-icon v-if="field.hide" @click="hideField(fieldI)" type="eye-invisible"
													class="mr-3 edit-delete" style="font-size: 17px;" />
												<a-tooltip v-else overlayClassName="change-tooltip-color">
													<template slot="title">Click eye icon to hide field</template>
													<a-icon type="eye" @click="hideField(fieldI)" class="mr-3 edit-delete"
														style="font-size: 17px;" />
												</a-tooltip>
												<svg @click="editField(field, fieldI)" xmlns="http://www.w3.org/2000/svg"
													width="15" height="15" viewBox="0 0 24 24" fill="none"
													stroke="currentColor" stroke-width="2" stroke-linecap="round"
													stroke-linejoin="round" class="feather feather-edit-2 edit-delete">
													<path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
													</path>
												</svg>
											</div>
											<div v-if="field.hide" class="absolute w-full h-full"
												style="z-index: 900; background-color: black; opacity: 0.03; left: 0px; top: 0px;" />
											<div v-if="field.hide">(Hidden)</div>
											<div class="mb-3" style="color: #40454c;">{{ field.label }}</div>
											<a-row :gutter="16" style="color: #40454c;">
												<div style="display: flex; justify-content: space-between;">
													<a-col v-show="field.prefix" style="width: 30%;">
														<a-form-item label="Prefix">
															<a-input style="pointer-events: none;" size="large">
															</a-input>
														</a-form-item>
													</a-col>
													<a-col style="width: 100%;">
														<a-form-item label="First Name" :required="field.required">
															<a-input style="pointer-events: none;" size="large">
															</a-input>
														</a-form-item>
													</a-col>
													<a-col v-show="field.middleInitial" style="width: 30%;">
														<a-form-item label="Middle Initial">
															<a-input style="pointer-events: none;" size="large">
															</a-input>
														</a-form-item>
													</a-col>
													<a-col style="width: 100%;">
														<a-form-item label="Last Name" :required="field.required">
															<a-input style="pointer-events: none;" size="large">
															</a-input>
														</a-form-item>
													</a-col>
													<a-col v-show="field.suffix" style="width: 30%;">
														<a-form-item label="Suffix">
															<a-input style="pointer-events: none;" size="large">
															</a-input>
														</a-form-item>
													</a-col>
												</div>
											</a-row>
										</a-card>
									</template>

									<!-- Phone Field -->
									<template v-else-if="field.type === 'phone'">
										<a-card :style="rightBar == field.id ? 'border-color:var(--orange)' : ''"
											:class="rightBar == field.id ? 'field-selected' : ''" class="mb-4 fieldCard"
											:key="field + fieldI" @mouseover="fieldHovering = fieldI"
											@mouseleave="fieldHovering = ''">
											<div v-show="fieldHovering === fieldI" class="dF"
												style="position: absolute; right: 24px; z-index: 1000;">
												<a-icon v-if="field.hide" @click="hideField(fieldI)" type="eye-invisible"
													class="mr-3 edit-delete" style="font-size: 17px;" />
												<a-tooltip v-else overlayClassName="change-tooltip-color">
													<template slot="title">Click eye icon to hide field</template>
													<a-icon type="eye" @click="hideField(fieldI)" class="mr-3 edit-delete"
														style="font-size: 17px;" />
												</a-tooltip>
												<svg @click="editField(field, fieldI)" xmlns="http://www.w3.org/2000/svg"
													width="15" height="15" viewBox="0 0 24 24" fill="none"
													stroke="currentColor" stroke-width="2" stroke-linecap="round"
													stroke-linejoin="round" class="feather feather-edit-2 edit-delete">
													<path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
													</path>
												</svg>
											</div>
											<div v-if="field.hide" class="absolute w-full h-full"
												style="z-index: 900; background-color: black; opacity: 0.03; left: 0px; top: 0px;" />
											<div v-if="field.hide">(Hidden)</div>
											<a-form-item :label="`${field.label || ' '}`" :required="field.required">
												<a-input style="pointer-events: none;" size="large"
													:placeholder="field.placeholder" type="number" v-model="field.default">
													<div slot="suffix" style="width: 12px; line-height: 12px;">
														<a-icon style="font-size: 12px;" type="caret-up" />
														<a-icon style="font-size: 12px;" type="caret-down" />
													</div>
												</a-input>
											</a-form-item>
										</a-card>
									</template>

									<!-- Address Field -->
									<template v-else-if="field.type === 'address'">
										<a-card :style="rightBar == field.id ? 'border-color:var(--orange)' : ''"
											:class="rightBar == field.id ? 'field-selected' : ''" class="mb-4 fieldCard"
											:key="field + fieldI" @mouseover="fieldHovering = fieldI"
											@mouseleave="fieldHovering = ''">
											<div v-show="fieldHovering === fieldI" class="dF"
												style="position: absolute; right: 24px; z-index: 1000;">
												<a-icon v-if="field.hide" @click="hideField(fieldI)" type="eye-invisible"
													class="mr-3 edit-delete" style="font-size: 17px;" />
												<a-tooltip v-else overlayClassName="change-tooltip-color">
													<template slot="title">Click eye icon to hide field</template>
													<a-icon type="eye" @click="hideField(fieldI)" class="mr-3 edit-delete"
														style="font-size: 17px;" />
												</a-tooltip>
												<svg @click="editField(field, fieldI)" xmlns="http://www.w3.org/2000/svg"
													width="15" height="15" viewBox="0 0 24 24" fill="none"
													stroke="currentColor" stroke-width="2" stroke-linecap="round"
													stroke-linejoin="round" class="feather feather-edit-2 edit-delete">
													<path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
													</path>
												</svg>
											</div>
											<div v-if="field.hide" class="absolute w-full h-full"
												style="z-index: 900; background-color: black; opacity: 0.03; left: 0px; top: 0px;" />
											<div v-if="field.hide">(Hidden)</div>
											<div class="mb-3" style="color: #40454c;">{{ field.label }}</div>
											<a-row :gutter="16">
												<a-col v-if="field.options.addressOne" :span="24">
													<a-form-item label="Address Line 1" :required="field.required">
														<a-input style="pointer-events: none;" size="large">
														</a-input>
													</a-form-item>
												</a-col>
											</a-row>
											<a-row :gutter="16">
												<a-col :span="24">
													<a-form-item v-if="field.options.addressTwo" label="Address Line 2">
														<a-input style="pointer-events: none;" size="large">
														</a-input>
													</a-form-item>
												</a-col>
											</a-row>
											<a-row
												v-if="field.options.city || field.options.province || field.options.postal"
												:gutter="16">
												<div style="display: flex; justify-content: space-between;">
													<a-col :span="6" v-show="field.options.city">
														<a-form-item label="City" :required="field.required">
															<a-input style="pointer-events: none;" size="large">
															</a-input>
														</a-form-item>
													</a-col>
													<a-col :span="6" v-show="field.options.province">
														<a-form-item label="State/Province" :required="field.required">
															<a-input style="pointer-events: none;" size="large">
															</a-input>
														</a-form-item>
													</a-col>
													<a-col :span="6" v-show="field.options.country">
														<a-form-item label="Country" :required="field.required">
															<a-select v-model="field.country"
																style="pointer-events: none; width:100%" size="large">
																<a-select-option value="Canada">Canada</a-select-option>
																<a-select-option value="United States">United
																	States</a-select-option>
															</a-select>
														</a-form-item>
													</a-col>
													<a-col :span="6" v-show="field.options.postal">
														<a-form-item label="ZIP/Postal Code" :required="field.required">
															<a-input style="pointer-events: none;" size="large">
															</a-input>
														</a-form-item>
													</a-col>
												</div>
											</a-row>
										</a-card>
									</template>

									<!-- isAgent Field -->
									<template v-else-if="field.type === 'isAgent'">
										<a-card :style="rightBar == field.id ? 'border-color:var(--orange)' : ''"
											:class="rightBar == field.id ? 'field-selected' : ''" class="mb-4 fieldCard"
											:key="field + fieldI" @mouseover="fieldHovering = fieldI"
											@mouseleave="fieldHovering = ''">
											<div v-show="fieldHovering === fieldI" class="dF"
												style="position: absolute; right: 24px; z-index: 1000;">
												<a-icon v-if="field.hide" @click="hideField(fieldI)" type="eye-invisible"
													class="mr-3 edit-delete" style="font-size: 17px;" />
												<a-tooltip v-else overlayClassName="change-tooltip-color">
													<template slot="title">Click eye icon to hide field</template>
													<a-icon type="eye" @click="hideField(fieldI)" class="mr-3 edit-delete"
														style="font-size: 17px;" />
												</a-tooltip>
												<svg @click="editField(field, fieldI)" xmlns="http://www.w3.org/2000/svg"
													width="15" height="15" viewBox="0 0 24 24" fill="none"
													stroke="currentColor" stroke-width="2" stroke-linecap="round"
													stroke-linejoin="round" class="feather feather-edit-2 edit-delete">
													<path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
													</path>
												</svg>
											</div>
											<div v-if="field.hide" class="absolute w-full h-full"
												style="z-index: 900; background-color: black; opacity: 0.03; left: 0px; top: 0px;" />
											<div v-if="field.hide">(Hidden)</div>
											<template v-if="field.displayType == 'checkbox'">
												<a-checkbox
													style="pointer-events: none; display: block; height: 32px; margin-left: 0 !important;"
													:value="field.default">
													{{ field.label }}<span v-if="field.required"
														style="color: var(--danger);"> *</span>
												</a-checkbox>
											</template>


											<template v-else-if="field.displayType == 'radio'">
												<div class="mb-3" style="color: #40454c;">{{ field.label }}<span
														v-if="field.required" style="color: var(--danger);"> *</span></div>
												<a-radio-group v-model="field.default">
													<a-radio style="pointer-events: none; display: block; height: 32px;"
														:value="1">
														Yes
													</a-radio>
													<a-radio style="pointer-events: none; display: block; height: 32px;"
														:value="0">
														No
													</a-radio>
												</a-radio-group>
											</template>


											<template v-else>
												<a-form-item :label="field.label || ' '" :required="field.required">
													<a-select style="width: 100%;" size="large"
														:placeholder="field.placeholder" v-model="field.default">
														<a-select-option :value="1">Yes</a-select-option>
														<a-select-option :value="0">No</a-select-option>
													</a-select>
												</a-form-item>
											</template>
										</a-card>
									</template>

									<!-- Text Field -->
									<template v-else-if="field.type === 'text'">
										<a-card :style="rightBar == field.id ? 'border-color:var(--orange)' : ''"
											:class="rightBar == field.id ? 'field-selected' : ''" class="mb-4 fieldCard"
											:key="field + fieldI" @mouseover="fieldHovering = fieldI"
											@mouseleave="fieldHovering = ''">
											<div v-show="fieldHovering === fieldI" class="dF"
												style="position: absolute; right: 24px; z-index: 1000;">
												<a-icon @click="copyField(field, fieldI)" type="copy"
													class="mr-3 edit-delete" />
												<svg @click="editField(field, fieldI)" xmlns="http://www.w3.org/2000/svg"
													width="15" height="15" viewBox="0 0 24 24" fill="none"
													stroke="currentColor" stroke-width="2" stroke-linecap="round"
													stroke-linejoin="round" class="feather feather-edit-2 mr-3 edit-delete">
													<path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
													</path>
												</svg>
												<svg @click="deleteField(field, fieldI)" xmlns="http://www.w3.org/2000/svg"
													width="15" height="15" viewBox="0 0 24 24" fill="none"
													stroke="currentColor" stroke-width="2" stroke-linecap="round"
													stroke-linejoin="round" class="feather feather-trash-2 edit-delete">
													<polyline points="3 6 5 6 21 6"></polyline>
													<path
														d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
													</path>
													<line x1="10" y1="11" x2="10" y2="17"></line>
													<line x1="14" y1="11" x2="14" y2="17"></line>
												</svg>
											</div>
											<a-form-item :label="field.label || ' '" :required="field.required">
												<a-input style="pointer-events: none;" size="large"
													:placeholder="field.placeholder" v-model="field.default">
												</a-input>
											</a-form-item>
										</a-card>
									</template>

									<!-- Number Field -->
									<template v-else-if="field.type === 'number'">
										<a-card :style="rightBar == field.id ? 'border-color:var(--orange)' : ''"
											:class="rightBar == field.id ? 'field-selected' : ''" class="mb-4 fieldCard"
											:key="field + fieldI" @mouseover="fieldHovering = fieldI"
											@mouseleave="fieldHovering = ''">
											<div v-show="fieldHovering === fieldI" class="dF"
												style="position: absolute; right: 24px; z-index: 1000;">
												<a-icon @click="copyField(field, fieldI)" type="copy"
													class="mr-3 edit-delete" />
												<svg @click="editField(field, fieldI)" xmlns="http://www.w3.org/2000/svg"
													width="15" height="15" viewBox="0 0 24 24" fill="none"
													stroke="currentColor" stroke-width="2" stroke-linecap="round"
													stroke-linejoin="round" class="feather feather-edit-2 mr-3 edit-delete">
													<path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
													</path>
												</svg>
												<svg @click="deleteField(field, fieldI)" xmlns="http://www.w3.org/2000/svg"
													width="15" height="15" viewBox="0 0 24 24" fill="none"
													stroke="currentColor" stroke-width="2" stroke-linecap="round"
													stroke-linejoin="round" class="feather feather-trash-2 edit-delete">
													<polyline points="3 6 5 6 21 6"></polyline>
													<path
														d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
													</path>
													<line x1="10" y1="11" x2="10" y2="17"></line>
													<line x1="14" y1="11" x2="14" y2="17"></line>
												</svg>
											</div>
											<a-form-item :label="field.label || ' '" :required="field.required">
												<a-input style="pointer-events: none;" size="large"
													:placeholder="field.placeholder" type="number" v-model="field.default">
													<div slot="suffix" style="width: 12px; line-height: 12px;">
														<a-icon style="font-size: 12px;" type="caret-up" />
														<a-icon style="font-size: 12px;" type="caret-down" />
													</div>
												</a-input>
											</a-form-item>
										</a-card>
									</template>

									<!-- Dropdown Field -->
									<template v-else-if="field.type === 'dropdown'">
										<a-card :style="rightBar == field.id ? 'border-color:var(--orange)' : ''"
											:class="rightBar == field.id ? 'field-selected' : ''" class="mb-4 fieldCard"
											:key="field + fieldI" @mouseover="fieldHovering = fieldI"
											@mouseleave="fieldHovering = ''">
											<div v-show="fieldHovering === fieldI" class="dF"
												style="position: absolute; right: 24px; z-index: 1000;">
												<a-icon @click="copyField(field, fieldI)" type="copy"
													class="mr-3 edit-delete" />
												<svg @click="editField(field, fieldI)" xmlns="http://www.w3.org/2000/svg"
													width="15" height="15" viewBox="0 0 24 24" fill="none"
													stroke="currentColor" stroke-width="2" stroke-linecap="round"
													stroke-linejoin="round" class="feather feather-edit-2 mr-3 edit-delete">
													<path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
													</path>
												</svg>
												<svg @click="deleteField(field, fieldI)" xmlns="http://www.w3.org/2000/svg"
													width="15" height="15" viewBox="0 0 24 24" fill="none"
													stroke="currentColor" stroke-width="2" stroke-linecap="round"
													stroke-linejoin="round" class="feather feather-trash-2 edit-delete">
													<polyline points="3 6 5 6 21 6"></polyline>
													<path
														d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
													</path>
													<line x1="10" y1="11" x2="10" y2="17"></line>
													<line x1="14" y1="11" x2="14" y2="17"></line>
												</svg>
											</div>
											<a-form-item :label="field.label || ' '" :required="field.required">
												<a-select style="width: 100%;" size="large" :placeholder="field.placeholder"
													v-model="field.default">
													<a-select-option v-for="(option, optionI) in field.options"
														:key='optionI + option.option'
														:value="option.option">{{ option.option }}</a-select-option>
												</a-select>
											</a-form-item>
										</a-card>
									</template>

									<!-- Radio Field -->
									<template v-else-if="field.type === 'radio'">
										<a-card :style="rightBar == field.id ? 'border-color:var(--orange)' : ''"
											:class="rightBar == field.id ? 'field-selected' : ''" class="mb-4 fieldCard"
											:key="field + fieldI" @mouseover="fieldHovering = fieldI"
											@mouseleave="fieldHovering = ''">
											<div v-show="fieldHovering === fieldI" class="dF"
												style="position: absolute; right: 24px; z-index: 1000;">
												<a-icon @click="copyField(field, fieldI)" type="copy"
													class="mr-3 edit-delete" />
												<svg @click="editField(field, fieldI)" xmlns="http://www.w3.org/2000/svg"
													width="15" height="15" viewBox="0 0 24 24" fill="none"
													stroke="currentColor" stroke-width="2" stroke-linecap="round"
													stroke-linejoin="round" class="feather feather-edit-2 mr-3 edit-delete">
													<path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
													</path>
												</svg>
												<svg @click="deleteField(field, fieldI)" xmlns="http://www.w3.org/2000/svg"
													width="15" height="15" viewBox="0 0 24 24" fill="none"
													stroke="currentColor" stroke-width="2" stroke-linecap="round"
													stroke-linejoin="round" class="feather feather-trash-2 edit-delete">
													<polyline points="3 6 5 6 21 6"></polyline>
													<path
														d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
													</path>
													<line x1="10" y1="11" x2="10" y2="17"></line>
													<line x1="14" y1="11" x2="14" y2="17"></line>
												</svg>
											</div>
											<div class="mb-3" style="color: #40454c;">{{ field.label }}<span
													v-if="field.required" style="color: var(--danger);"> *</span></div>
											<a-radio-group v-model="field.default">
												<a-radio v-for="(option, optionI) in field.options"
													style="pointer-events: none; display: block; height: 32px;"
													:value="option.option" :key="option + optionI">
													{{ option.option }}
												</a-radio>
												<a-radio v-if="field.other"
													style="pointer-events: none; display: block; height: 32px;"
													value="other">
													<span class="mr-2" style="padding-left: 2px;"
														v-if="field.otherText">{{ field.otherText }}</span><a-input
														:style="{ width: 100, marginLeft: 10 }" />
												</a-radio>
											</a-radio-group>
										</a-card>
									</template>

									<!-- Checkbox Field -->
									<template v-else-if="field.type === 'checkbox'">
										<a-card :style="rightBar == field.id ? 'border-color:var(--orange)' : ''"
											:class="rightBar == field.id ? 'field-selected' : ''" class="mb-4 fieldCard"
											:key="field + fieldI" @mouseover="fieldHovering = fieldI"
											@mouseleave="fieldHovering = ''">
											<div v-show="fieldHovering === fieldI" class="dF"
												style="position: absolute; right: 24px; z-index: 1000;">
												<a-icon @click="copyField(field, fieldI)" type="copy"
													class="mr-3 edit-delete" />
												<svg @click="editField(field, fieldI)" xmlns="http://www.w3.org/2000/svg"
													width="15" height="15" viewBox="0 0 24 24" fill="none"
													stroke="currentColor" stroke-width="2" stroke-linecap="round"
													stroke-linejoin="round" class="feather feather-edit-2 mr-3 edit-delete">
													<path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
													</path>
												</svg>
												<svg @click="deleteField(field, fieldI)" xmlns="http://www.w3.org/2000/svg"
													width="15" height="15" viewBox="0 0 24 24" fill="none"
													stroke="currentColor" stroke-width="2" stroke-linecap="round"
													stroke-linejoin="round" class="feather feather-trash-2 edit-delete">
													<polyline points="3 6 5 6 21 6"></polyline>
													<path
														d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
													</path>
													<line x1="10" y1="11" x2="10" y2="17"></line>
													<line x1="14" y1="11" x2="14" y2="17"></line>
												</svg>
											</div>
											<template v-if="field.multiple">
												<div class="mb-3" style="color: #40454c;">{{ field.label }}<span
														v-if="field.required" style="color: var(--danger);"> *</span></div>
												<a-checkbox-group :value="[field.default]">
													<a-checkbox v-for="(option, optionI) in field.options"
														style="pointer-events: none; display: block; height: 32px; margin-left: 0 !important;"
														:value="option.option" :key="option + optionI">
														{{ option.option }}
													</a-checkbox>
													<a-checkbox v-if="field.other"
														style="pointer-events: none; display: flex; align-items: center; height: 32px; margin-left: 0 !important;"
														value="other">
														<div class="dF aC">
															<span class="mr-2" style="padding-left: 2px;"
																v-if="field.otherText">{{ field.otherText }}</span><a-input
																:style="{ width: 100, marginLeft: 10 }" />
														</div>
													</a-checkbox>
												</a-checkbox-group>
											</template>
											<template v-else>
												<a-checkbox
													style="pointer-events: none; display: block; height: 32px; margin-left: 0 !important;"
													:value="field.label">
													{{ field.label }}<span v-if="field.required"
														style="color: var(--danger);"> *</span>
												</a-checkbox>
											</template>
										</a-card>
									</template>

									<!-- Date/Time Field -->
									<template v-else-if="field.type === 'date'">
										<a-card :style="rightBar == field.id ? 'border-color:var(--orange)' : ''"
											:class="rightBar == field.id ? 'field-selected' : ''" class="mb-4 fieldCard"
											:key="field + fieldI" @mouseover="fieldHovering = fieldI"
											@mouseleave="fieldHovering = ''">
											<div v-show="fieldHovering === fieldI" class="dF"
												style="position: absolute; right: 24px; z-index: 1000;">
												<a-icon @click="copyField(field, fieldI)" type="copy"
													class="mr-3 edit-delete" />
												<svg @click="editField(field, fieldI)" xmlns="http://www.w3.org/2000/svg"
													width="15" height="15" viewBox="0 0 24 24" fill="none"
													stroke="currentColor" stroke-width="2" stroke-linecap="round"
													stroke-linejoin="round" class="feather feather-edit-2 mr-3 edit-delete">
													<path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
													</path>
												</svg>
												<svg @click="deleteField(field, fieldI)" xmlns="http://www.w3.org/2000/svg"
													width="15" height="15" viewBox="0 0 24 24" fill="none"
													stroke="currentColor" stroke-width="2" stroke-linecap="round"
													stroke-linejoin="round" class="feather feather-trash-2 edit-delete">
													<polyline points="3 6 5 6 21 6"></polyline>
													<path
														d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
													</path>
													<line x1="10" y1="11" x2="10" y2="17"></line>
													<line x1="14" y1="11" x2="14" y2="17"></line>
												</svg>
											</div>
											<div class="mb-3" style="color: #40454c;">{{ field.label }}<span
													v-if="field.required" style="color: var(--danger);"> *</span></div>
											<div class="dF">
												<a-date-picker v-model="field.default" :placeholder="field.placeholder"
													style="pointer-events: none;" class="mr-3" />
												<a-date-picker v-if="field.timeEnable" show-time placeholder="Select Time"
													style="pointer-events: none;" />
											</div>
										</a-card>
									</template>

									<!-- File Field -->
									<template v-else-if="field.type === 'file'">
										<a-card :style="rightBar == field.id ? 'border-color:var(--orange)' : ''"
											:class="rightBar == field.id ? 'field-selected' : ''" class="mb-4 fieldCard"
											:key="field + fieldI" @mouseover="fieldHovering = fieldI"
											@mouseleave="fieldHovering = ''">
											<div v-show="fieldHovering === fieldI" class="dF"
												style="position: absolute; right: 24px; z-index: 1000;">
												<a-icon @click="copyField(field, fieldI)" type="copy"
													class="mr-3 edit-delete" />
												<svg @click="editField(field, fieldI)" xmlns="http://www.w3.org/2000/svg"
													width="15" height="15" viewBox="0 0 24 24" fill="none"
													stroke="currentColor" stroke-width="2" stroke-linecap="round"
													stroke-linejoin="round" class="feather feather-edit-2 mr-3 edit-delete">
													<path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
													</path>
												</svg>
												<svg @click="deleteField(field, fieldI)" xmlns="http://www.w3.org/2000/svg"
													width="15" height="15" viewBox="0 0 24 24" fill="none"
													stroke="currentColor" stroke-width="2" stroke-linecap="round"
													stroke-linejoin="round" class="feather feather-trash-2 edit-delete">
													<polyline points="3 6 5 6 21 6"></polyline>
													<path
														d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
													</path>
													<line x1="10" y1="11" x2="10" y2="17"></line>
													<line x1="14" y1="11" x2="14" y2="17"></line>
												</svg>
											</div>
											<div class="mb-3" style="color: #40454c;">{{ field.label }}<span
													v-if="field.required" style="color: var(--danger);"> *</span></div>
											<div class="dF">
												<a-button> <a-icon type="upload" /> {{ field.buttonLabel }} </a-button>
											</div>
										</a-card>
									</template>

									<!-- Header Field -->
									<template v-else-if="field.type === 'header'">
										<a-card :style="rightBar == field.id ? 'border-color:var(--orange)' : ''"
											:class="rightBar == field.id ? 'field-selected' : ''" class="mb-4 fieldCard"
											:key="field + fieldI" @mouseover="fieldHovering = fieldI"
											@mouseleave="fieldHovering = ''">
											<div v-show="fieldHovering === fieldI" class="dF"
												style="position: absolute; right: 24px; z-index: 1000;">
												<a-icon @click="copyField(field, fieldI)" type="copy"
													class="mr-3 edit-delete" />
												<svg @click="editField(field, fieldI)" xmlns="http://www.w3.org/2000/svg"
													width="15" height="15" viewBox="0 0 24 24" fill="none"
													stroke="currentColor" stroke-width="2" stroke-linecap="round"
													stroke-linejoin="round" class="feather feather-edit-2 mr-3 edit-delete">
													<path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
													</path>
												</svg>
												<svg @click="deleteField(field, fieldI)" xmlns="http://www.w3.org/2000/svg"
													width="15" height="15" viewBox="0 0 24 24" fill="none"
													stroke="currentColor" stroke-width="2" stroke-linecap="round"
													stroke-linejoin="round" class="feather feather-trash-2 edit-delete">
													<polyline points="3 6 5 6 21 6"></polyline>
													<path
														d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
													</path>
													<line x1="10" y1="11" x2="10" y2="17"></line>
													<line x1="14" y1="11" x2="14" y2="17"></line>
												</svg>
											</div>
											<div style="font-size: 18px;">
												{{ field.label }}
											</div>
											<div v-if="field.description && field.description.length" class="mt-2">
												{{ field.description }}
											</div>

										</a-card>
									</template>

									<!-- Tag Field -->
									<template v-else-if="field.type == 'tag'">
										<a-card :style="rightBar == field.id ? 'border-color:var(--orange)' : ''"
											:class="rightBar == field.id ? 'field-selected' : ''" class="mb-4 fieldCard"
											:key="field + fieldI" @mouseover="fieldHovering = fieldI"
											@mouseleave="fieldHovering = ''">
											<div v-show="fieldHovering === fieldI" class="dF"
												style="position: absolute; right: 24px; z-index: 1000;">
												<a-icon @click="copyField(field, fieldI)" type="copy"
													class="mr-3 edit-delete" />
												<svg @click="editField(field, fieldI)" xmlns="http://www.w3.org/2000/svg"
													width="15" height="15" viewBox="0 0 24 24" fill="none"
													stroke="currentColor" stroke-width="2" stroke-linecap="round"
													stroke-linejoin="round" class="feather feather-edit-2 mr-3 edit-delete">
													<path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
													</path>
												</svg>
												<svg @click="deleteField(field, fieldI)" xmlns="http://www.w3.org/2000/svg"
													width="15" height="15" viewBox="0 0 24 24" fill="none"
													stroke="currentColor" stroke-width="2" stroke-linecap="round"
													stroke-linejoin="round" class="feather feather-trash-2 edit-delete">
													<polyline points="3 6 5 6 21 6"></polyline>
													<path
														d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
													</path>
													<line x1="10" y1="11" x2="10" y2="17"></line>
													<line x1="14" y1="11" x2="14" y2="17"></line>
												</svg>
											</div>
											<template v-if="field.multiple">
												<div class="mb-3" style="color: #40454c;">{{ field.label }}<span
														v-if="field.required" style="color: var(--danger);"> *</span></div>
												<a-checkbox-group>
													<a-checkbox v-show="field.selectAll"
														style="pointer-events: none; display: block; height: 32px; margin-left: 0 !important;">
														Select All
													</a-checkbox>
													<a-checkbox v-for="(option, optionI) in field.options"
														style="pointer-events: none; display: block; height: 32px; margin-left: 0 !important;"
														:value="option.option" :key="option + optionI">
														{{ option.option }}
													</a-checkbox>
												</a-checkbox-group>
											</template>
											<template v-else>
												<a-form-item :label="field.label || ' '" :required="field.required">
													<a-select style="width: 100%;" size="large">
														<a-select-option v-for="(option, optionI) in field.options"
															:key='optionI + option.option'
															:value="option.option">{{ option.option }}</a-select-option>
													</a-select>
												</a-form-item>
											</template>
										</a-card>
									</template>

									<!-- Select Child -->
									<template v-else-if="field.type == 'child'">
										<a-card :style="rightBar == field.id ? 'border-color:var(--orange)' : ''"
											:class="rightBar == field.id ? 'field-selected' : ''" class="mb-4 fieldCard"
											:key="field + fieldI" @mouseover="fieldHovering = fieldI"
											@mouseleave="fieldHovering = ''">
											<div v-show="fieldHovering === fieldI" class="dF"
												style="position: absolute; right: 24px; z-index: 1000;">
												<a-icon v-if="field.hide" @click="hideField(fieldI)" type="eye-invisible"
													class="mr-3 edit-delete" style="font-size: 17px;" />
												<a-tooltip v-else overlayClassName="change-tooltip-color">
													<template slot="title">Click eye icon to hide field</template>
													<a-icon type="eye" @click="hideField(fieldI)" class="mr-3 edit-delete"
														style="font-size: 17px;" />
												</a-tooltip>
												<!-- <a-icon @click="copyField(field, fieldI)" type="copy"
													class="mr-3 edit-delete" /> -->
												<svg @click="editField(field, fieldI)" xmlns="http://www.w3.org/2000/svg"
													width="15" height="15" viewBox="0 0 24 24" fill="none"
													stroke="currentColor" stroke-width="2" stroke-linecap="round"
													stroke-linejoin="round" class="feather feather-edit-2 edit-delete">
													<path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
													</path>
												</svg>
												<!-- <svg @click="deleteField(field, fieldI)" xmlns="http://www.w3.org/2000/svg"
													width="15" height="15" viewBox="0 0 24 24" fill="none"
													stroke="currentColor" stroke-width="2" stroke-linecap="round"
													stroke-linejoin="round" class="feather feather-trash-2 edit-delete">
													<polyline points="3 6 5 6 21 6"></polyline>
													<path
														d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
													</path>
													<line x1="10" y1="11" x2="10" y2="17"></line>
													<line x1="14" y1="11" x2="14" y2="17"></line>
												</svg> -->
											</div>
											<div v-if="field.hide" class="absolute w-full h-full"
												style="z-index: 900; background-color: black; opacity: 0.03; left: 0px; top: 0px;" />
											<div v-if="field.hide">(Hidden)</div>
											<div class="mb-3" style="color: #40454c;">{{ field.label }}</div>
											<a-checkbox v-show="field.selectAll"
												style="pointer-events: none; display: block; height: 32px; margin-left: 0 !important;">
												Select All
											</a-checkbox>
											<template v-if="field.options.find(x => x.active)">
												<template v-for="(option, optionI) in field.options">
													<a-checkbox v-if="option.active" :checked="option.selected"
														style="pointer-events: none; display: block; height: 32px; margin-left: 0 !important;"
														:value="option.id" :key="option + optionI">
														{{ option.option || option.label }}
													</a-checkbox>
												</template>
											</template>
											<template v-else>
												<!-- no active options -->
												You have no active options. Please activate an option to the field.
											</template>
										</a-card>
									</template>

									<!-- worksheet fields -->

									<!-- Lot or Unit Field -->
									<template v-else-if="['lot', 'unit'].includes(field.type)">
										<a-card :style="rightBar == field.id ? 'border-color:var(--orange)' : ''"
											:class="rightBar == field.id ? 'field-selected' : ''" class="mb-4 fieldCard"
											:key="field + fieldI" @mouseover="fieldHovering = fieldI"
											@mouseleave="fieldHovering = ''">
											<div v-show="fieldHovering === fieldI" class="dF aS"
												style="position: absolute; right: 24px; z-index: 1000;">
												<svg @click="editField(field, fieldI)" xmlns="http://www.w3.org/2000/svg"
													width="15" height="15" viewBox="0 0 24 24" fill="none"
													stroke="currentColor" stroke-width="2" stroke-linecap="round"
													stroke-linejoin="round" class="feather feather-edit-2 edit-delete">
													<path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
													</path>
												</svg>
											</div>
											<div class="mb-3" style="color: #40454c;">{{ field.label }}</div>
											<a-row :gutter="16" style="color: #40454c;">
												<div style="display: flex; justify-content: space-between;">
													<a-col style="width: 100%;">
														<a-form-item :label="field.label1" :required="field.required1">
															<a-select style="pointer-events: none;width: 100%;" size="large"
																:placeholder="field.placeholder1">
																<a-select-option v-for="(option, optionI) in field.options"
																	:key='optionI + option.option'
																	:value="option.option">{{ option.option }}</a-select-option>
															</a-select>
														</a-form-item>
													</a-col>
													<a-col v-show="field.showSecondChoice" style="width: 100%;">
														<a-form-item :label="field.label2" :required="field.required2">
															<a-select style="pointer-events: none;width: 100%;" size="large"
																:placeholder="field.placeholder2">
																<a-select-option v-for="(option, optionI) in field.options"
																	:key='optionI + option.option'
																	:value="option.option">{{ option.option }}</a-select-option>
															</a-select>
														</a-form-item>
													</a-col>
													<a-col v-show="field.showThirdChoice" style="width: 100%;">
														<a-form-item :label="field.label3" :required="field.required3">
															<a-select style="pointer-events: none;width: 100%;" size="large"
																:placeholder="field.placeholder3">
																<a-select-option v-for="(option, optionI) in field.options"
																	:key='optionI + option.option'
																	:value="option.option">{{ option.option }}</a-select-option>
															</a-select>
														</a-form-item>
													</a-col>
												</div>
											</a-row>
										</a-card>
									</template>

									<!-- Lot Field -->
									<template v-else-if="field.type === 'lot'">
										<a-card :style="rightBar == field.id ? 'border-color:var(--orange)' : ''"
											:class="rightBar == field.id ? 'field-selected' : ''" class="mb-4 fieldCard"
											:key="field + fieldI" @mouseover="fieldHovering = fieldI"
											@mouseleave="fieldHovering = ''">
											<div v-show="fieldHovering === fieldI" class="dF aS"
												style="position: absolute; right: 24px; z-index: 1000;">
												<svg @click="editField(field, fieldI)" xmlns="http://www.w3.org/2000/svg"
													width="15" height="15" viewBox="0 0 24 24" fill="none"
													stroke="currentColor" stroke-width="2" stroke-linecap="round"
													stroke-linejoin="round" class="feather feather-edit-2 edit-delete">
													<path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
													</path>
												</svg>
											</div>
											<div class="mb-3" style="color: #40454c;">{{ field.label }}</div>
											<a-row :gutter="16" style="color: #40454c;">
												<div style="display: flex; justify-content: space-between;">
													<a-col style="width: 100%;">
														<a-form-item :label="field.label1" :required="field.required1">
															<a-select style="pointer-events: none;width: 100%;" size="large"
																:placeholder="field.placeholder1">
																<a-select-option v-for="(option, optionI) in field.options"
																	:key='optionI + option.option'
																	:value="option.option">{{ option.option }}</a-select-option>
															</a-select>
														</a-form-item>
														<a-form-item :label="field.elevationLabel1" :required="field.required1">
															<a-select style="pointer-events: none;width: 100%;" size="large"
																:placeholder="field.elevationPlaceholder1">
																<a-select-option v-for="(option, optionI) in field.options"
																	:key='optionI + option.option'
																	:value="option.option">{{ option.option }}</a-select-option>
															</a-select>
														</a-form-item>
													</a-col>
													<a-col v-show="field.showSecondChoice" style="width: 100%;">
														<a-form-item :label="field.label2" :required="field.required2">
															<a-select style="pointer-events: none;width: 100%;" size="large"
																:placeholder="field.placeholder2">
																<a-select-option v-for="(option, optionI) in field.options"
																	:key='optionI + option.option'
																	:value="option.option">{{ option.option }}</a-select-option>
															</a-select>
														</a-form-item>
														<a-form-item :label="field.elevationLabel2" :required="field.required2">
															<a-select style="pointer-events: none;width: 100%;" size="large"
																:placeholder="field.elevationPlaceholder2">
																<a-select-option v-for="(option, optionI) in field.options"
																	:key='optionI + option.option'
																	:value="option.option">{{ option.option }}</a-select-option>
															</a-select>
														</a-form-item>
													</a-col>
													<a-col v-show="field.showThirdChoice" style="width: 100%;">
														<a-form-item :label="field.label3" :required="field.required3">
															<a-select style="pointer-events: none;width: 100%;" size="large"
																:placeholder="field.placeholder3">
																<a-select-option v-for="(option, optionI) in field.options"
																	:key='optionI + option.option'
																	:value="option.option">{{ option.option }}</a-select-option>
															</a-select>
														</a-form-item>
														<a-form-item :label="field.elevationLabel3" :required="field.required3">
															<a-select style="pointer-events: none;width: 100%;" size="large"
																:placeholder="field.elevationPlaceholder3">
																<a-select-option v-for="(option, optionI) in field.options"
																	:key='optionI + option.option'
																	:value="option.option">{{ option.option }}</a-select-option>
															</a-select>
														</a-form-item>
													</a-col>
												</div>
											</a-row>
										</a-card>
									</template>

									<!-- Add Ons Field -->
									<template v-else-if="field.type === 'addOns'">
										<a-card :style="rightBar == field.id ? 'border-color:var(--orange)' : ''"
											:class="rightBar == field.id ? 'field-selected' : ''" class="mb-4 fieldCard"
											:key="field + fieldI" @mouseover="fieldHovering = fieldI"
											@mouseleave="fieldHovering = ''">
											<div v-show="fieldHovering === fieldI" class="dF aS"
												style="position: absolute; right: 24px; z-index: 1000;">
												<a-icon v-if="field.hide" @click="hideField(fieldI)" type="eye-invisible"
													class="mr-3 edit-delete" style="font-size: 17px;" />
												<a-tooltip v-else overlayClassName="change-tooltip-color">
													<template slot="title">Click eye icon to hide field</template>
													<a-icon type="eye" @click="hideField(fieldI)" class="mr-3 edit-delete"
														style="font-size: 17px;" />
												</a-tooltip>
												<svg @click="editField(field, fieldI)" xmlns="http://www.w3.org/2000/svg"
													width="15" height="15" viewBox="0 0 24 24" fill="none"
													stroke="currentColor" stroke-width="2" stroke-linecap="round"
													stroke-linejoin="round" class="feather feather-edit-2 edit-delete">
													<path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
													</path>
												</svg>
											</div>
											<div v-if="field.hide" class="absolute w-full h-full"
												style="z-index: 900; background-color: black; opacity: 0.03; left: 0px; top: 0px;" />
											<div v-if="field.hide">(Hidden)</div>
											<div class="mb-3" style="color: #40454c;">{{ field.label }}</div>
											<a-row :gutter="16" style="color: #40454c;">
												<div style="display: flex; justify-content: space-between;">
													<a-col v-show="field.parking.show" style="width: 100%;">
														<a-form-item :label="field.parking.label"
															:required="field.parking.required">
															<a-select style="pointer-events: none;width: 100%;" size="large"
																:placeholder="field.parking.placeholder">
																<a-select-option
																	v-for="(option, optionI) in field.parking.options"
																	:key='optionI + option.option'
																	:value="option.option">{{ option.option }}</a-select-option>
															</a-select>
														</a-form-item>
													</a-col>
													<a-col v-show="field.lockers.show" style="width: 100%;">
														<a-form-item :label="field.lockers.label"
															:required="field.lockers.required">
															<a-select style="pointer-events: none;width: 100%;" size="large"
																:placeholder="field.lockers.placeholder">
																<a-select-option
																	v-for="(option, optionI) in field.lockers.options"
																	:key='optionI + option.option'
																	:value="option.option">{{ option.option }}</a-select-option>
															</a-select>
														</a-form-item>
													</a-col>
													<a-col v-show="field.bikeRacks.show" style="width: 100%;">
														<a-form-item :label="field.bikeRacks.label"
															:required="field.bikeRacks.required">
															<a-select style="pointer-events: none;width: 100%;" size="large"
																:placeholder="field.bikeRacks.placeholder">
																<a-select-option
																	v-for="(option, optionI) in field.bikeRacks.options"
																	:key='optionI + option.option'
																	:value="option.option">{{ option.option }}</a-select-option>
															</a-select>
														</a-form-item>
													</a-col>
												</div>
											</a-row>
										</a-card>
									</template>

									<!-- Purchaser Field -->
									<template v-else-if="field.type === 'purchaser'">
										<a-card :style="rightBar == field.id ? 'border-color:var(--orange)' : ''"
											:class="rightBar == field.id ? 'field-selected' : ''" class="mb-4 fieldCard"
											:key="field + fieldI" @mouseover="fieldHovering = fieldI"
											@mouseleave="fieldHovering = ''">
											<div v-show="fieldHovering === fieldI" class="dF aS"
												style="position: absolute; right: 24px; z-index: 1000;">
												<svg @click="editField(field, fieldI)" xmlns="http://www.w3.org/2000/svg"
													width="15" height="15" viewBox="0 0 24 24" fill="none"
													stroke="currentColor" stroke-width="2" stroke-linecap="round"
													stroke-linejoin="round" class="feather feather-edit-2 edit-delete">
													<path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
													</path>
												</svg>
												<svg v-if="field.id.toString().endsWith('XXX')"
													@click="deleteField(field, fieldI)" xmlns="http://www.w3.org/2000/svg"
													width="15" height="15" viewBox="0 0 24 24" fill="none"
													stroke="currentColor" stroke-width="2" stroke-linecap="round"
													stroke-linejoin="round"
													class="feather feather-trash-2 ml-3 edit-delete">
													<polyline points="3 6 5 6 21 6"></polyline>
													<path
														d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
													</path>
													<line x1="10" y1="11" x2="10" y2="17"></line>
													<line x1="14" y1="11" x2="14" y2="17"></line>
												</svg>
											</div>
											<div class="mb-3" style="color: #40454c;">{{ field.label }}</div>
											<a-row :gutter="16" style="color: #40454c;">
												<div style="display: flex; justify-content: space-between;">
													<a-col style="width: 100%;">
														<a-form-item label="First Name" :required="field.required">
															<a-input style="pointer-events: none;" size="large">
															</a-input>
														</a-form-item>
													</a-col>
													<a-col style="width: 100%;">
														<a-form-item label="Last Name" :required="field.required">
															<a-input style="pointer-events: none;" size="large">
															</a-input>
														</a-form-item>
													</a-col>
													<a-col style="width: 100%;">
														<a-form-item label="Middle Name">
															<a-input style="pointer-events: none;" size="large">
															</a-input>
														</a-form-item>
													</a-col>
												</div>
											</a-row>
											<a-row :gutter="16" style="color: #40454c;">
												<div style="display: flex; justify-content: space-between;">
													<a-col style="width: 100%;">
														<a-form-item label="Date of Birth" :required="field.required">
															<a-input style="pointer-events: none;" size="large">
															</a-input>
														</a-form-item>
													</a-col>
													<a-col style="width: 100%;">
														<a-form-item label="Email" :required="field.required">
															<a-input style="pointer-events: none;" size="large">
															</a-input>
														</a-form-item>
													</a-col>
													<a-col style="width: 100%;">
														<a-form-item label="Phone #" :required="field.required">
															<a-input style="pointer-events: none;" size="large">
															</a-input>
														</a-form-item>
													</a-col>
												</div>
											</a-row>
											<a-row :gutter="16" style="color: #40454c;">
												<div style="display: flex; justify-content: space-between;">
													<a-col style="width: 100%;">
														<a-form-item label="Business Phone#">
															<a-input style="pointer-events: none;" size="large">
															</a-input>
														</a-form-item>
													</a-col>
													<a-col style="width: 100%;">
														<a-form-item label="Cell Phone#">
															<a-input style="pointer-events: none;" size="large">
															</a-input>
														</a-form-item>
													</a-col>
													<a-col style="width: 100%;">
														<a-form-item label="Company">
															<a-input style="pointer-events: none;" size="large">
															</a-input>
														</a-form-item>
													</a-col>
												</div>
											</a-row>
											<a-row :gutter="16" style="color: #40454c;">
												<div style="display: flex; justify-content: space-between;">
													<a-col style="width: 100%;">
														<a-form-item label="Occupation">
															<a-input style="pointer-events: none;" size="large">
															</a-input>
														</a-form-item>
													</a-col>
													<a-col style="width: 100%;">
														<a-form-item label="Job Title">
															<a-input style="pointer-events: none;" size="large">
															</a-input>
														</a-form-item>
													</a-col>
													<a-col style="width: 100%;">
														<a-form-item label="SSN/SIN #">
															<a-input style="pointer-events: none;" size="large">
															</a-input>
														</a-form-item>
													</a-col>
												</div>
											</a-row>
											<a-row :gutter="16" style="color: #40454c;">
												<div style="display: flex; justify-content: space-between;">
													<a-col style="width: 100%;">
														<a-form-item label="Driver License #" :required="field.required">
															<a-input style="pointer-events: none;" size="large">
															</a-input>
														</a-form-item>
													</a-col>
													<a-col style="width: 100%;">
														<a-form-item label="License Issuing Jurisdiction">
															<a-input style="pointer-events: none;" size="large">
															</a-input>
														</a-form-item>
													</a-col>
													<a-col style="width: 100%;">
														<a-form-item label="License Expiry">
															<a-date-picker placeholder="eg. 1984-02-28"
																style="pointer-events: none; width:100%" size="large" />
														</a-form-item>
													</a-col>
												</div>
											</a-row>
											<a-row :gutter="16" style="color: #40454c;">
												<div style="display: flex; justify-content: space-between;">
													<a-col style="width: 100%;">
														<a-form-item label="Identification Type" :required="field.required">
															<a-select default-value="pp" v-model="field.idType"
																style="width: 100%;" size="large">
																<a-select-option value="pp">US/CA Passport</a-select-option>
																<a-select-option value="other">Other</a-select-option>
																<a-select-option v-for="(option, optionI) in field.options"
																	:key='optionI + option.option'
																	:value="option.option">{{ option.option }}</a-select-option>
															</a-select>
														</a-form-item>
													</a-col>
													<a-col style="width: 100%;">
														<a-form-item
															:label="field.idType === 'pp' ? 'Passport #' : 'Other #'"
															:required="field.required">
															<a-input style="pointer-events: none;" size="large">
															</a-input>
														</a-form-item>
													</a-col>
													<a-col style="width: 100%;">
														<a-form-item
															:label="field.idType === 'pp' ? 'Passport Issuing Jurisdiction' : 'Other Issuing Jurisdiction'">
															<a-input style="pointer-events: none;" size="large">
															</a-input>
														</a-form-item>
													</a-col>
												</div>
											</a-row>
											<a-row :gutter="16">
												<div style="display: flex; justify-content: space-between;">
													<a-col style="width: 100%;">
														<a-form-item
															:label="field.idType === 'pp' ? 'Passport Expiry' : 'Other Expiry'">
															<a-date-picker placeholder="eg. 1984-02-28"
																style="pointer-events: none; width:100%" size="large" />
														</a-form-item>
													</a-col>
													<a-col style="width: 100%;">
														<a-form-item label="Address Line 1" :required="field.required">
															<a-input style="pointer-events: none;" size="large">
															</a-input>
														</a-form-item>
													</a-col>
													<a-col style="width: 100%;">
														<a-form-item label="Address Line 2">
															<a-input style="pointer-events: none;" size="large">
															</a-input>
														</a-form-item>
													</a-col>
												</div>
											</a-row>
											<a-row :gutter="16">
												<div style="display: flex; justify-content: space-between;">
													<a-col style="width: 100%;">
														<a-form-item label="City" :required="field.required">
															<a-input style="pointer-events: none;" size="large">
															</a-input>
														</a-form-item>
													</a-col>
													<a-col style="width: 100%;">
														<a-form-item label="State/Province" :required="field.required">
															<a-input style="pointer-events: none;" size="large">
															</a-input>
														</a-form-item>
													</a-col>
													<a-col style="width: 100%;">
														<a-form-item label="ZIP/Postal Code" :required="field.required">
															<a-input style="pointer-events: none;" size="large">
															</a-input>
														</a-form-item>
													</a-col>
													<a-col style="width: 100%;">
														<a-form-item label="Country" :required="field.required">
															<a-select style="pointer-events: none; width:100%" size="large">
																<a-select-option value="Canada">Canada</a-select-option>
																<a-select-option value="United States">United
																	States</a-select-option>
															</a-select>
														</a-form-item>
													</a-col>
												</div>
											</a-row>
										</a-card>
									</template>

									<!-- solicitor Field -->
									<template v-else-if="field.type === 'solicitor'">
										<a-card :style="rightBar == field.id ? 'border-color:var(--orange)' : ''"
											:class="rightBar == field.id ? 'field-selected' : ''" class="mb-4 fieldCard"
											:key="field + fieldI" @mouseover="fieldHovering = fieldI"
											@mouseleave="fieldHovering = ''">
											<div v-show="fieldHovering === fieldI" class="dF aS"
												style="position: absolute; right: 24px; z-index: 1000;">
												<a-icon v-if="field.hide" @click="hideField(fieldI)" type="eye-invisible"
													class="mr-3 edit-delete" style="font-size: 17px;" />
												<a-tooltip v-else overlayClassName="change-tooltip-color">
													<template slot="title">Click eye icon to hide field</template>
													<a-icon type="eye" @click="hideField(fieldI)" class="mr-3 edit-delete"
														style="font-size: 17px;" />
												</a-tooltip>
												<svg @click="editField(field, fieldI)" xmlns="http://www.w3.org/2000/svg"
													width="15" height="15" viewBox="0 0 24 24" fill="none"
													stroke="currentColor" stroke-width="2" stroke-linecap="round"
													stroke-linejoin="round" class="feather feather-edit-2 edit-delete">
													<path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
													</path>
												</svg>
												<svg @click="deleteField(field, fieldI)" xmlns="http://www.w3.org/2000/svg"
													width="15" height="15" viewBox="0 0 24 24" fill="none"
													stroke="currentColor" stroke-width="2" stroke-linecap="round"
													stroke-linejoin="round"
													class="feather feather-trash-2 ml-3 edit-delete">
													<polyline points="3 6 5 6 21 6"></polyline>
													<path
														d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
													</path>
													<line x1="10" y1="11" x2="10" y2="17"></line>
													<line x1="14" y1="11" x2="14" y2="17"></line>
												</svg>
											</div>
											<div v-if="field.hide" class="absolute w-full h-full"
												style="z-index: 900; background-color: black; opacity: 0.03; left: 0px; top: 0px;" />
											<div v-if="field.hide">(Hidden)</div>
											<div class="mb-3" style="color: #40454c;">{{ field.label }}</div>
											<a-row :gutter="16" style="color: #40454c;">
												<a-col :span="8">
													<a-form-item label="First Name" :required="field.required">
														<a-input style="pointer-events: none;" size="large">
														</a-input>
													</a-form-item>
												</a-col>
												<a-col :span="8">
													<a-form-item label="Last Name" :required="field.required">
														<a-input style="pointer-events: none;" size="large">
														</a-input>
													</a-form-item>
												</a-col>
												<a-col :span="8">
													<a-form-item label="Email" :required="field.required">
														<a-input style="pointer-events: none;" size="large">
														</a-input>
													</a-form-item>
												</a-col>
											</a-row>
											<a-row :gutter="16" style="color: #40454c;">
												<a-col :span="8">
													<a-form-item label="Phone #">
														<a-input style="pointer-events: none;" size="large">
														</a-input>
													</a-form-item>
												</a-col>
												<a-col :span="8">
													<a-form-item label="Fax #">
														<a-input style="pointer-events: none;" size="large">
														</a-input>
													</a-form-item>
												</a-col>
												<a-col :span="8">
													<a-form-item label="Company Name">
														<a-input style="pointer-events: none;" size="large">
														</a-input>
													</a-form-item>
												</a-col>
											</a-row>
											<a-row :gutter="16">
												<a-col :span="8">
													<a-form-item label="Job Title">
														<a-input style="pointer-events: none;" size="large">
														</a-input>
													</a-form-item>
												</a-col>
												<a-col :span="8">
													<a-form-item label="Company Address Line 1" :required="field.required">
														<a-input style="pointer-events: none;" size="large">
														</a-input>
													</a-form-item>
												</a-col>
												<a-col :span="8">
													<a-form-item label="Company Address Line 2">
														<a-input style="pointer-events: none;" size="large">
														</a-input>
													</a-form-item>
												</a-col>
											</a-row>
											<a-row :gutter="16">
												<div style="display: flex; justify-content: space-between;">
													<a-col :span="6">
														<a-form-item label="City" :required="field.required">
															<a-input style="pointer-events: none;" size="large">
															</a-input>
														</a-form-item>
													</a-col>
													<a-col :span="6">
														<a-form-item label="State/Province" :required="field.required">
															<a-input style="pointer-events: none;" size="large">
															</a-input>
														</a-form-item>
													</a-col>
													<a-col :span="6">
														<a-form-item label="ZIP/Postal Code" :required="field.required">
															<a-input style="pointer-events: none;" size="large">
															</a-input>
														</a-form-item>
													</a-col>
													<a-col :span="6">
														<a-form-item label="Country" :required="field.required">
															<a-input style="pointer-events: none;" size="large">
															</a-input>
														</a-form-item>
													</a-col>
												</div>
											</a-row>
										</a-card>
									</template>
								</template>
							</draggable>
							<div style="width: 100%; text-align: center;">
								<div @click="fieldSelect = true" class="addField">
									<a-icon type="plus" style="color: white;" />
								</div>
							</div>
						</a-card>
					</div>

					<!-- Right side of form -->
					<div v-if="formLoaded && form.type !== 'worksheet' && currentWidth >= 1250" class="rightSide rightForm">
						<a-card class="mb-3 tags" title="Apply Tags to Registrants">
							<a-tooltip slot="extra" overlayClassName="change-tooltip-color">
								<template slot="title">
									These tags will be applied to Registrants as they enter through this form.
								</template>
								<a-icon type="question-circle"
									style="line-height: 25px; margin-top: 5px; color: var(--orange);" />
							</a-tooltip>
							<a-select v-model="form.tags" mode="tags" style="width: 100%"
								placeholder="Interested Buyer/Senior/Have Pets..." size="large">
								<a-select-option v-for="tag in tags" :value="tag.name" :key="tag.id">
									{{ tag.name }}
								</a-select-option>
							</a-select>
						</a-card>
						<a-card class="mb-3 autoresponder" title="Autoresponder">
							<a-tooltip slot="extra" overlayClassName="change-tooltip-color">
								<template slot="title">
									This will be the autoresponder that users receive after submitting this form.
								</template>
								<a-icon type="question-circle"
									style="line-height: 25px; margin-top: 5px; color: var(--orange);" />
							</a-tooltip>
							<a-form-item label="Autoresponder Subject Line">
								<a-input size="large" v-model="form.options.autoresponderSubject"
									placeholder="Subject Line" />
							</a-form-item>
							<a-form-item label="Autoresponder Preheader Line">
								<a-input size="large" v-model="form.options.autoresponderPreheader"
									placeholder="Preheader Line" />
							</a-form-item>
							<a-form-item label="Select an Autoresponder Template">
								<a-select v-model="form.options.autoresponder" size="large" style="width: 100%">
									<a-select-option value="">
										None
									</a-select-option>
									<a-select-option v-for="(temp, tempI) in templates" :value="temp.id" :key="temp + tempI">
										{{ temp.name }}
									</a-select-option>
								</a-select>
							</a-form-item>
							<a-tooltip overlayClassName="change-tooltip-color">
								<template slot="title">
									The Sender Email Address identifies the address where the email is coming from. To add
									more addresses, go to <span><a :href="`https://email.bildhive.${$tld}/settings`"
											target="__blank" style="color: var(--orange);">Broadcast Email
											Builder</a></span> app.
								</template>
								<a-icon type="question-circle"
									style="line-height: 25px; position: absolute; margin-top: 1px; left: 210px; z-index: 2; color: var(--orange);" />
							</a-tooltip>
							<a-form-item label="Select a Sender Email Address">
								<a-select v-model="form.options.fromEmail" size="large" style="width: 100%">
									<a-select-option value="">
										Default (no-reply@mail.bildhive.com)
									</a-select-option>
									<a-select-option v-for="(email, emailI) in senders" :value="email.email"
										:key="email + emailI">
										{{ email.email }}
									</a-select-option>
								</a-select>
							</a-form-item>
						</a-card>
						<a-card class="mb-3 notification" title="Submission Notification">
							<a-tooltip slot="extra" overlayClassName="change-tooltip-color">
								<template slot="title">
									This will send an Email/Notifications to the following users of New Registrants.
								</template>
								<a-icon type="question-circle"
									style="line-height: 25px; margin-top: 5px; color: var(--orange);" />
							</a-tooltip>
							<a-select mode="multiple" v-model="form.users" style="width: 100%" size="large"
								placeholder="Select Users">
								<a-select-option v-for="user in users" :value="user.id" :key="`SDFSXXXXXX${user.id}`">
									{{ user.firstName }} {{ user.lastName }} <span
										style="font-style: italic;">{{ '(' + user.email + ')' }}</span>
								</a-select-option>
							</a-select>
						</a-card>
						<a-card class="mb-3" title="Submit Button">
							Submit Button Label
							<a-input v-model="form.options.submitLabel" size="large" class="mt-2" placeholder="Submit">
							</a-input>
						</a-card>
						<a-card class="mb-3 thankyou" title="Thank You Message">
							<a-tooltip slot="extra" overlayClassName="change-tooltip-color">
								<template slot="title">
									The message that shows up after the registrant submits the form.
								</template>
								<a-icon type="question-circle"
									style="line-height: 25px; margin-top: 5px; color: var(--orange);" />
							</a-tooltip>
							Headline
							<a-input v-model="form.options.thanks.headline" size="large" class="mt-2 mb-4"
								placeholder="Thank you for...">
							</a-input>
							Text
							<a-textarea v-model="form.options.thanks.text" :auto-size="{ minRows: 5, maxRows: 10 }"
								class="mt-2" />
						</a-card>
						<a-card class="mb-3" title="Consent">
							<div class="dF aC">
								Enabled?
								<a-switch class="ml-3" v-model="form.options.consent.enabled" />
							</div>
							<a-textarea v-model="form.options.consent.text" :auto-size="{ minRows: 3, maxRows: 10 }"
								class="mt-3" :disabled="!form.options.consent.enabled" />
						</a-card>
						<a-card class="mb-3" title="SMS Consent">
							<div class="dF aC">
								Enabled?
								<a-switch class="ml-3" v-model="form.options.smsConsent.enabled" />
							</div>
							<a-textarea v-model="form.options.smsConsent.text" :auto-size="{ minRows: 3, maxRows: 10 }"
								class="mt-3" :disabled="!form.options.smsConsent.enabled" />
						</a-card>
					</div>

					<div v-else-if="formLoaded && currentWidth >= 1250" class="rightSide rightForm">
						<a-card class="mb-3 tags" title="Apply Tags to Worksheet">
							<a-tooltip slot="extra" overlayClassName="change-tooltip-color">
								<template slot="title">
									These tags will be applied to Worksheet as they enter through this form.
								</template>
								<a-icon type="question-circle"
									style="line-height: 25px; margin-top: 5px; color: var(--orange);" />
							</a-tooltip>
							<a-select v-model="form.tags" mode="tags" style="width: 100%"
								placeholder="Interested Buyer/Senior/Have Pets..." size="large">
								<a-select-option v-for="tag in tags" :value="tag.name" :key="tag.id">
									{{ tag.name }}
								</a-select-option>
							</a-select>
						</a-card>
						<a-card class="mb-3 realtors" title="Worksheet Realtors">
							<a-tooltip slot="extra" overlayClassName="change-tooltip-color">
								<template slot="title">
									This worksheet will be used when specified Realtor fill up the worksheet.
								</template>
								<a-icon type="question-circle"
									style="line-height: 25px; margin-top: 5px; color: var(--orange);" />
							</a-tooltip>
							<a-select mode="multiple" v-model="form.options.serveAgents" style="width: 100%"
								size="large" placeholder="Select Users" @change="onRealtorChange">
								<a-select-option value="all">
									All Realtors (Default)
								</a-select-option>
								<a-select-option v-for="user in realtors" :value="user.id" :key="user.id">
									{{ user.firstName }} {{ user.lastName }} <span
										style="font-style: italic;">{{ '(' + user.email + ')' }}</span>
								</a-select-option>
							</a-select>
						</a-card>
						<a-card class="mb-3 notification" title="Submission Notification">
							<a-tooltip slot="extra" overlayClassName="change-tooltip-color">
								<template slot="title">
									This will send an Notifications to the following users of New Worksheet Submission.
								</template>
								<a-icon type="question-circle"
									style="line-height: 25px; margin-top: 5px; color: var(--orange);" />
							</a-tooltip>
							<a-select mode="multiple" v-model="form.users" style="width: 100%" size="large"
								placeholder="Select Users">
								<a-select-option v-for="user in users" :value="user.id" :key="user.id">
									{{ user.firstName }} {{ user.lastName }} <span
										style="font-style: italic;">{{ '(' + user.email + ')' }}</span>
								</a-select-option>
							</a-select>
						</a-card>
					</div>
				</div>
			</div>

			<!-- Field List -->
			<fieldBar :fieldList="form.fields" :icons="generateIcons">
				<div v-for="(field, fieldI) in form.fields" :slot="field.id" :key="field + fieldI">

					<!-- Email -->
					<template v-if="field.type === 'email'">
						<h6 class="mb-3">General</h6>

						<div class="mb-2" style="font-size: 14px;">Field Label</div>
						<a-input v-model="field.label">
						</a-input>
						<div class="mt-3 mb-2" style="font-size: 14px;">Placeholder</div>
						<a-input v-model="field.placeholder">
						</a-input>
						<a-checkbox class="mt-3" :checked="field.required" @change="field.required = !field.required"
							:disabled="true">
							Required
						</a-checkbox>
					</template>

					<!-- Name -->
					<template v-else-if="field.type === 'name'">
						<h6 class="mb-3">General</h6>

						<div class="mb-2" style="font-size: 14px;">Field Label</div>
						<a-input v-model="field.label">
						</a-input>

						<a-checkbox class="mt-3" :checked="field.required" @change="field.required = !field.required">
							Required
						</a-checkbox>

						<hr style="margin-top: 25px; margin-bottom: 25px;">

						<h6 class="mb-3">Field Specific</h6>

						<div class="mt-3 mb-2" style="font-size: 14px;">Options</div>
						<a-checkbox style="width: 100%; margin-bottom: 0.5rem;" :checked="field.prefix"
							@change="field.prefix = !field.prefix">Prefix</a-checkbox>
						<a-checkbox style="width: 100%; margin-bottom: 0.5rem; margin-left: 0;"
							:checked="field.middleInitial" @change="field.middleInitial = !field.middleInitial">Middle
							Initial</a-checkbox>
						<a-checkbox style="width: 100%; margin-bottom: 0.5rem; margin-left: 0;" :checked="field.suffix"
							@change="field.suffix = !field.suffix">Suffix</a-checkbox>
					</template>

					<!-- Phone -->
					<template v-else-if="field.type === 'phone'">
						<h6 class="mb-3">General</h6>

						<div class="mb-2" style="font-size: 14px;">Field Label</div>
						<a-input v-model="field.label">
						</a-input>
						<div class="mt-3 mb-2" style="font-size: 14px;">Placeholder</div>
						<a-input v-model="field.placeholder">
						</a-input>
						<a-checkbox class="mt-3" :checked="field.required" @change="field.required = !field.required">
							Required
						</a-checkbox>

						<hr style="margin-top: 25px; margin-bottom: 25px;">

						<h6 class="mb-3">Field Specific</h6>

						<div class="mb-2" style="font-size: 14px;">Min and Max Values</div>
						<div class="mt-3 mb-2 dF" style="font-size: 14px; line-height: 32px;">
							Min
							<a-input v-model="field.min" type="number" class="ml-2">
							</a-input>
						</div>
						<div class="mt-3 mb-2 dF" style="font-size: 14px; line-height: 32px;">
							Max
							<a-input v-model="field.max" type="number" class="ml-2">
							</a-input>
						</div>

						<div class="mb-2 mt-4" style="font-size: 14px;">Number of Digits Required</div>
						<div class="mt-3 mb-2 dF" style="font-size: 14px; line-height: 32px;">
							<a-input v-model="field.digitLength" type="number" :min="1">
							</a-input>
						</div>
						<div class="mt-3 mb-2" style="font-size: 14px;">Default Value</div>
						<a-input v-model="field.default" type="number">
						</a-input>
					</template>

					<!-- Address -->
					<template v-else-if="field.type === 'address'">
						<h6 class="mb-3">General</h6>

						<div class="mb-2" style="font-size: 14px;">Field Label</div>
						<a-input v-model="field.label">
						</a-input>
						<a-checkbox class="mt-3" :checked="field.required" @change="field.required = !field.required">
							Required
						</a-checkbox>

						<hr style="margin-top: 25px; margin-bottom: 25px;">

						<h6 class="mb-3">Field Specific</h6>

						<div class="mb-2" style="font-size: 14px;">Country</div>
						<a-select :default-value="field.country" style="width: 100%;" v-model="field.country">
							<a-select-option value="Canada">
								Canada
							</a-select-option>
							<a-select-option value="United States">
								United States
							</a-select-option>
						</a-select>

						<div class="mt-3 mb-2" style="font-size: 14px;">Options</div>
						<a-checkbox style="width: 100%; margin-bottom: 0.5rem;" :checked="field.options.addressOne"
							@change="field.options.addressOne = !field.options.addressOne">Address Line 1</a-checkbox>
						<a-checkbox style="width: 100%; margin-bottom: 0.5rem; margin-left: 0;"
							:checked="field.options.addressTwo"
							@change="field.options.addressTwo = !field.options.addressTwo">Address Line 2</a-checkbox>
						<a-checkbox style="width: 100%; margin-bottom: 0.5rem; margin-left: 0;"
							:checked="field.options.city"
							@change="field.options.city = !field.options.city">City</a-checkbox>
						<a-checkbox style="width: 100%; margin-bottom: 0.5rem; margin-left: 0;"
							:checked="field.options.province"
							@change="field.options.province = !field.options.province">State/Province</a-checkbox>
						<a-checkbox style="width: 100%; margin-bottom: 0.5rem; margin-left: 0;"
							:checked="field.options.country"
							@change="field.options.country = !field.options.country">Country</a-checkbox>
						<a-checkbox style="width: 100%; margin-bottom: 0.5rem; margin-left: 0;"
							:checked="field.options.postal"
							@change="field.options.postal = !field.options.postal">ZIP/Postal Code</a-checkbox>
					</template>

					<!-- isAgent -->
					<template v-else-if="field.type === 'isAgent'">
						<h6 class="mb-3">General</h6>

						<div class="mb-2" style="font-size: 14px;">Field Label</div>
						<a-input v-model="field.label">
						</a-input>
						<div class="dF mt-3">
							<a-checkbox :checked="field.required" @change="field.required = !field.required">
								Required
							</a-checkbox>
						</div>

						<div class="mb-2 mt-4" style="font-size: 14px;">Field Display Type</div>
						<a-select style="width: 100%;" v-model="field.displayType">
							<a-select-option value="checkbox">
								Checkbox
							</a-select-option>
							<a-select-option value="radio">
								Radio
							</a-select-option>
							<a-select-option value="dropdown">
								Dropdown
							</a-select-option>
						</a-select>

						<template v-if="field.displayType != 'checkbox'">
							<hr style="margin-top: 25px; margin-bottom: 25px;">

							<h6 class="mb-3">Field Specific</h6>
							<div class="mt-3 mb-2" style="font-size: 14px;">Default Value</div>
							<a-select style="width: 100%;" v-model="field.default">
								<a-select-option value="">
									No Default Value
								</a-select-option>
								<a-select-option :value="1">
									Yes
								</a-select-option>
								<a-select-option :value="0">
									No
								</a-select-option>
							</a-select>
						</template>
					</template>

					<!-- Text Input -->
					<template v-else-if="field.type === 'text'">
						<h6 class="mb-3">General</h6>

						<div class="mb-2" style="font-size: 14px;">Field Type</div>
						<a-select :value="field.type" class="w-full" @change="(e) => updateFieldType(e, field)">
							<template v-for="(type, typeI) in allFieldTypes">
								<a-select-option v-if="type == 'name' || type == 'phone' || type == 'address'" :value="type"
									:key="type + typeI" :disabled="currentFields.includes(type)">
									{{ type.charAt(0).toUpperCase() + type.slice(1) }}
								</a-select-option>
								<template v-else>
									<template v-if="childInstances && childInstances.length">
										<a-select-option :value="type" :key="type + typeI">
											{{ type.charAt(0).toUpperCase() + type.slice(1) }}
										</a-select-option>
									</template>
									<template v-else>
										<a-select-option v-if="type != 'child'" :value="type" :key="type + typeI + typeI">
											{{ type.charAt(0).toUpperCase() + type.slice(1) }}
										</a-select-option>
									</template>
								</template>
							</template>
						</a-select>

						<div class="mb-2 mt-3" style="font-size: 14px;">Field Label</div>
						<a-input v-model="field.label">
						</a-input>
						<div class="mt-3 mb-2" style="font-size: 14px;">Placeholder</div>
						<a-input v-model="field.placeholder">
						</a-input>
						<a-checkbox class="mt-3" :checked="field.required" @change="field.required = !field.required">
							Required
						</a-checkbox>
						<a-checkbox class="remove-leftmargin mt-3" :checked="field.includeEmail"
							@change="field.includeEmail = !field.includeEmail">
							Include in Email Notification
						</a-checkbox>

						<hr style="margin-top: 25px; margin-bottom: 25px;">

						<h6 class="mb-3">Field Specific</h6>

						<div class="mt-3 mb-2" style="font-size: 14px;">Default Value</div>
						<a-input v-model="field.default">
						</a-input>
					</template>

					<!-- Number -->
					<template v-else-if="field.type === 'number'">
						<h6 class="mb-3">General</h6>

						<div class="mb-2" style="font-size: 14px;">Field Type</div>
						<a-select :value="field.type" class="w-full" @change="(e) => updateFieldType(e, field)">
							<template v-for="(type, typeI) in allFieldTypes">
								<a-select-option v-if="type == 'name' || type == 'phone' || type == 'address'" :value="type"
									:key="type + typeI" :disabled="currentFields.includes(type)">
									{{ type.charAt(0).toUpperCase() + type.slice(1) }}
								</a-select-option>
								<template v-else>
									<template v-if="childInstances && childInstances.length">
										<a-select-option :value="type" :key="type + typeI">
											{{ type.charAt(0).toUpperCase() + type.slice(1) }}
										</a-select-option>
									</template>
									<template v-else>
										<a-select-option v-if="type != 'child'" :value="type" :key="type + typeI + typeI">
											{{ type.charAt(0).toUpperCase() + type.slice(1) }}
										</a-select-option>
									</template>
								</template>
							</template>
						</a-select>

						<div class="mb-2 mt-3" style="font-size: 14px;">Field Label</div>
						<a-input v-model="field.label">
						</a-input>
						<div class="mt-3 mb-2" style="font-size: 14px;">Placeholder</div>
						<a-input v-model="field.placeholder">
						</a-input>
						<a-checkbox class="mt-3" :checked="field.required" @change="field.required = !field.required">
							Required
						</a-checkbox>
						<a-checkbox class="remove-leftmargin mt-3" :checked="field.includeEmail"
							@change="field.includeEmail = !field.includeEmail">
							Include in Email Notification
						</a-checkbox>

						<hr style="margin-top: 25px; margin-bottom: 25px;">

						<h6 class="mb-3">Field Specific</h6>

						<div class="mb-2" style="font-size: 14px;">Min and Max Values</div>
						<div class="mt-3 mb-2 dF" style="font-size: 14px; line-height: 32px;">
							Min
							<a-input v-model="field.min" type="number" class="ml-2">
							</a-input>
						</div>
						<div class="mt-3 mb-2 dF" style="font-size: 14px; line-height: 32px;">
							Max
							<a-input v-model="field.max" type="number" class="ml-2">
							</a-input>
						</div>

						<div class="mb-2 mt-4" style="font-size: 14px;">Number of Digits Required</div>
						<div class="mt-3 mb-2 dF" style="font-size: 14px; line-height: 32px;">
							<a-input v-model="field.digitLength" type="number" :min="1">
							</a-input>
						</div>
						<div class="mt-3 mb-2" style="font-size: 14px;">Default Value</div>
						<a-input v-model="field.default" type="number">
						</a-input>
					</template>

					<!-- Dropdown List -->
					<template v-else-if="field.type === 'dropdown'">
						<h6 class="mb-3">General</h6>

						<div class="mb-2" style="font-size: 14px;">Field Type</div>
						<a-select :value="field.type" class="w-full" @change="(e) => updateFieldType(e, field)">
							<template v-for="(type, typeI) in allFieldTypes">
								<a-select-option v-if="type == 'name' || type == 'phone' || type == 'address'" :value="type"
									:key="type + typeI" :disabled="currentFields.includes(type)">
									{{ type.charAt(0).toUpperCase() + type.slice(1) }}
								</a-select-option>
								<template v-else>
									<template v-if="childInstances && childInstances.length">
										<a-select-option :value="type" :key="type + typeI">
											{{ type.charAt(0).toUpperCase() + type.slice(1) }}
										</a-select-option>
									</template>
									<template v-else>
										<a-select-option v-if="type != 'child'" :value="type" :key="type + typeI + typeI">
											{{ type.charAt(0).toUpperCase() + type.slice(1) }}
										</a-select-option>
									</template>
								</template>
							</template>
						</a-select>

						<div class="mb-2 mt-3" style="font-size: 14px;">Field Label</div>
						<a-input v-model="field.label">
						</a-input>
						<div class="mt-3 mb-2" style="font-size: 14px;">Placeholder</div>
						<a-input v-model="field.placeholder">
						</a-input>
						<a-checkbox class="mt-3" :checked="field.required" @change="field.required = !field.required">
							Required
						</a-checkbox>
						<a-checkbox class="remove-leftmargin mt-3" :checked="field.includeEmail"
							@change="field.includeEmail = !field.includeEmail">
							Include in Email Notification
						</a-checkbox>

						<hr style="margin-top: 25px; margin-bottom: 25px;">

						<div class="mt-3 mb-2" style="font-size: 14px;">Options</div>
						<div v-for="(option, optionI) in field.options" :key="option + optionI" class="dF">
							<a-input class="mr-3 mb-2" v-model="option.option"></a-input>
							<div class="dF mb-2" style="width: 20%;">
								<a-icon class="mr-2" @click="field.options.splice(optionI + 1, 0, { option: 'New' });"
									style="margin-top: auto; margin-bottom: auto; color: var(--orange); cursor: pointer;"
									type="plus-circle" />
								<a-icon v-show="optionI > 1" @click="field.options.splice(optionI, 1)"
									style="margin-top: auto; margin-bottom: auto; color: var(--danger); cursor: pointer;"
									type="minus-circle" />
							</div>
						</div>
						<div class="mt-3 mb-2" style="font-size: 14px;">Default Value</div>
						<a-select show-search option-filter-prop="children" style="width: 100%;"
							:filter-option="filterOption" v-model="field.default">
							<a-select-option value="">
								No Default Value
							</a-select-option>
							<a-select-option v-for="(option, optionI) in field.options" :value="option.option"
								:key="option + optionI">
								{{ option.option }}
							</a-select-option>
						</a-select>
					</template>

					<!-- Radio / Checkbox -->
					<template v-else-if="field.type === 'radio' || field.type === 'checkbox'">
						<h6 class="mb-3">General</h6>

						<div class="mb-2" style="font-size: 14px;">Field Type</div>
						<a-select :value="field.type" class="w-full" @change="(e) => updateFieldType(e, field)">
							<template v-for="(type, typeI) in allFieldTypes">
								<a-select-option v-if="type == 'name' || type == 'phone' || type == 'address'" :value="type"
									:key="type + typeI" :disabled="currentFields.includes(type)">
									{{ type.charAt(0).toUpperCase() + type.slice(1) }}
								</a-select-option>
								<template v-else>
									<template v-if="childInstances && childInstances.length">
										<a-select-option :value="type" :key="type + typeI">
											{{ type.charAt(0).toUpperCase() + type.slice(1) }}
										</a-select-option>
									</template>
									<template v-else>
										<a-select-option v-if="type != 'child'" :value="type" :key="type + typeI + typeI">
											{{ type.charAt(0).toUpperCase() + type.slice(1) }}
										</a-select-option>
									</template>
								</template>
							</template>
						</a-select>

						<div class="mb-2 mt-3" style="font-size: 14px;">Field Label</div>
						<a-input v-model="field.label">
						</a-input>
						<div class="dF mt-3">
							<a-checkbox :checked="field.required" @change="field.required = !field.required">
								Required
							</a-checkbox>
							<template v-if="field.type === 'checkbox'">
								<a-checkbox :checked="field.multiple" @change="field.multiple = !field.multiple">
									Multiple Options?
								</a-checkbox>
							</template>
						</div>
						<a-checkbox class="remove-leftmargin mt-3" :checked="field.includeEmail"
							@change="field.includeEmail = !field.includeEmail">
							Include in Email Notification
						</a-checkbox>
						<template v-if="field.type === 'radio' || (field.type === 'checkbox' && field.multiple)">
							<hr style="margin-top: 25px; margin-bottom: 25px;">

							<div class="mt-3 mb-2" style="font-size: 14px;">Options</div>
							<div v-for="(option, optionI) in field.options" :key="option + optionI" class="dF">
								<a-input class="mr-3 mb-2" v-model="option.option"></a-input>
								<div class="dF mb-2" style="width: 20%;">
									<a-icon class="mr-2" @click="field.options.splice(optionI + 1, 0, { option: 'New' });"
										style="margin-top: auto; margin-bottom: auto; color: var(--orange); cursor: pointer;"
										type="plus-circle" />
									<a-icon v-show="optionI > 1" @click="field.options.splice(optionI, 1)"
										style="margin-top: auto; margin-bottom: auto; color: var(--danger); cursor: pointer;"
										type="minus-circle" />
								</div>
							</div>
							<a-checkbox class="mt-3 mb-2" :checked="field.other" @change="field.other = !field.other">
								Add "Other"
							</a-checkbox>

							<div class="mb-2 mt-2" style="font-size: 14px;">Other Text</div>
							<a-input v-model="field.otherText">
							</a-input>

							<div class="mt-3 mb-2" style="font-size: 14px;">Default Value</div>
							<a-select show-search option-filter-prop="children" style="width: 100%;"
								:filter-option="filterOption" v-model="field.default">
								<a-select-option value="">
									No Default Value
								</a-select-option>
								<a-select-option v-for="(option, optionI) in field.options" :value="option.option"
									:key="option + optionI">
									{{ option.option }}
								</a-select-option>
							</a-select>
						</template>
					</template>

					<!-- Date -->
					<template v-else-if="field.type === 'date'">
						<h6 class="mb-3">General</h6>

						<div class="mb-2" style="font-size: 14px;">Field Type</div>
						<a-select :value="field.type" class="w-full" @change="(e) => updateFieldType(e, field)">
							<template v-for="(type, typeI) in allFieldTypes">
								<a-select-option v-if="type == 'name' || type == 'phone' || type == 'address'" :value="type"
									:key="type + typeI" :disabled="currentFields.includes(type)">
									{{ type.charAt(0).toUpperCase() + type.slice(1) }}
								</a-select-option>
								<template v-else>
									<template v-if="childInstances && childInstances.length">
										<a-select-option :value="type" :key="type + typeI">
											{{ type.charAt(0).toUpperCase() + type.slice(1) }}
										</a-select-option>
									</template>
									<template v-else>
										<a-select-option v-if="type != 'child'" :value="type" :key="type + typeI + typeI">
											{{ type.charAt(0).toUpperCase() + type.slice(1) }}
										</a-select-option>
									</template>
								</template>
							</template>
						</a-select>

						<div class="mb-2 mt-3" style="font-size: 14px;">Field Label</div>
						<a-input v-model="field.label">
						</a-input>
						<div class="mt-3 mb-2" style="font-size: 14px;">Placeholder</div>
						<a-input v-model="field.placeholder">
						</a-input>
						<a-checkbox class="mt-3" :checked="field.required" @change="field.required = !field.required">
							Required
						</a-checkbox>
						<a-checkbox class="remove-leftmargin mt-3" :checked="field.includeEmail"
							@change="field.includeEmail = !field.includeEmail">
							Include in Email Notification
						</a-checkbox>
						<hr style="margin-top: 25px; margin-bottom: 25px;">

						<h6 class="mb-3">Field Specific</h6>

						<a-checkbox style="margin-left: auto;" :checked="field.timeEnable"
							@change="field.timeEnable = !field.timeEnable">
							Enable Time
						</a-checkbox>
					</template>

					<!-- File -->
					<template v-else-if="field.type === 'file'">
						<h6 class="mb-3">General</h6>

						<div class="mb-2" style="font-size: 14px;">Field Type</div>
						<a-select :value="field.type" class="w-full" @change="(e) => updateFieldType(e, field)">
							<template v-for="(type, typeI) in allFieldTypes">
								<a-select-option v-if="type == 'name' || type == 'phone' || type == 'address'" :value="type"
									:key="type + typeI" :disabled="currentFields.includes(type)">
									{{ type.charAt(0).toUpperCase() + type.slice(1) }}
								</a-select-option>
								<template v-else>
									<template v-if="childInstances && childInstances.length">
										<a-select-option :value="type" :key="type + typeI">
											{{ type.charAt(0).toUpperCase() + type.slice(1) }}
										</a-select-option>
									</template>
									<template v-else>
										<a-select-option v-if="type != 'child'" :value="type" :key="type + typeI + typeI">
											{{ type.charAt(0).toUpperCase() + type.slice(1) }}
										</a-select-option>
									</template>
								</template>
							</template>
						</a-select>

						<a-form>
							<a-form-item label="Field Label" class="mb-1 mt-3">
								<a-input v-model="field.label" />
							</a-form-item>
							<a-checkbox class="mt-3" :checked="field.required" @change="field.required = !field.required">
								Required
							</a-checkbox>
							<a-checkbox class="remove-leftmargin mt-3" :checked="field.includeEmail"
								@change="field.includeEmail = !field.includeEmail">
								Include in Email Notification
							</a-checkbox>
						</a-form>

						<hr style="margin-top: 25px; margin-bottom: 25px;">

						<h6 class="mb-3">Field Specific</h6>

						<a-form>
							<a-form-item label="Acceptable Files">
								<a-select :default-value="field.format" style="width: 100%;" v-model="field.format">
									<a-select-option value="any">
										Any
									</a-select-option>
									<a-select-option value="images">
										Images (.jpg,.png)
									</a-select-option>
									<a-select-option value="documents">
										Documents (*.pdf,*.docx)
									</a-select-option>
								</a-select>
							</a-form-item>
							<a-form-item label="Max Size">
								<a-select :default-value="field.format" style="width: 100%;" v-model="field.maxSize">
									<a-select-option :value="100">
										100mb
									</a-select-option>
									<a-select-option :value="25">
										25mb
									</a-select-option>
									<a-select-option :value="10">
										10mb
									</a-select-option>
								</a-select>
							</a-form-item>
						</a-form>


					</template>

					<!-- Header -->
					<template v-else-if="field.type === 'header'">
						<h6 class="mb-3">General</h6>

						<div class="mb-2" style="font-size: 14px;">Field Type</div>
						<a-select :value="field.type" class="w-full" @change="(e) => updateFieldType(e, field)">
							<template v-for="(type, typeI) in allFieldTypes">
								<a-select-option v-if="type == 'name' || type == 'phone' || type == 'address'" :value="type"
									:key="type + typeI" :disabled="currentFields.includes(type)">
									{{ type.charAt(0).toUpperCase() + type.slice(1) }}
								</a-select-option>
								<template v-else>
									<template v-if="childInstances && childInstances.length">
										<a-select-option :value="type" :key="type + typeI">
											{{ type.charAt(0).toUpperCase() + type.slice(1) }}
										</a-select-option>
									</template>
									<template v-else>
										<a-select-option v-if="type != 'child'" :value="type" :key="type + typeI + typeI">
											{{ type.charAt(0).toUpperCase() + type.slice(1) }}
										</a-select-option>
									</template>
								</template>
							</template>
						</a-select>

						<a-form>
							<a-form-item label="Header Title" class="mb-1 mt-3">
								<a-input v-model="field.label" />
							</a-form-item>
							<a-form-item label="Header Description" class="mb-1">
								<a-textarea v-model="field.description" />
							</a-form-item>
						</a-form>

						<hr style="margin-top: 25px; margin-bottom: 25px;">

					</template>

					<!-- Tag Field -->
					<template v-else-if="field.type === 'tag'">
						<h6 class="mb-3">General</h6>

						<div class="mb-2" style="font-size: 14px;">Field Type</div>
						<a-select :value="field.type" class="w-full" @change="(e) => updateFieldType(e, field)">
							<template v-for="(type, typeI) in allFieldTypes">
								<a-select-option v-if="type == 'name' || type == 'phone' || type == 'address'" :value="type"
									:key="type + typeI" :disabled="currentFields.includes(type)">
									{{ type.charAt(0).toUpperCase() + type.slice(1) }}
								</a-select-option>
								<template v-else>
									<template v-if="childInstances && childInstances.length">
										<a-select-option :value="type" :key="type + typeI">
											{{ type.charAt(0).toUpperCase() + type.slice(1) }}
										</a-select-option>
									</template>
									<template v-else>
										<a-select-option v-if="type != 'child'" :value="type" :key="type + typeI + typeI">
											{{ type.charAt(0).toUpperCase() + type.slice(1) }}
										</a-select-option>
									</template>
								</template>
							</template>
						</a-select>

						<div class="mb-2 mt-3" style="font-size: 14px;">Field Label</div>
						<a-input v-model="field.label">
						</a-input>
						<div class="mt-3">
							<a-checkbox :checked="field.required" @change="field.required = !field.required">
								Required
							</a-checkbox>
							<a-checkbox class="remove-leftmargin mt-3" :checked="field.includeEmail"
								@change="field.includeEmail = !field.includeEmail">
								Include in Email Notification
							</a-checkbox>
							<template v-if="field.type === 'tag'">
								<a-checkbox class="mt-3 remove-leftmargin" :checked="field.multiple"
									@change="e => field.multiple = e.target.checked">
									Multiple Answers?
								</a-checkbox>
							</template>
							<template v-if="field.multiple">
								<a-checkbox class="remove-leftmargin mt-3" :checked="field.selectAll"
									@change="field.selectAll = !field.selectAll">
									Enable Select All
								</a-checkbox>
								<a-checkbox class="remove-leftmargin mt-3" :checked="field.dropdown"
									@change="field.dropdown = !field.dropdown">
									Display as a dropdown?
								</a-checkbox>
								<template v-if="field.dropdown">
									<div class="mt-3 mb-2" style="font-size: 14px;">Placeholder</div>
										<a-input v-model="field.placeholder">
									</a-input>
								</template>
							</template>
						</div>
						<template v-if="field.type === 'tag'">
							<hr style="margin-top: 25px; margin-bottom: 25px;">

							<div class="mt-3 mb-2" style="font-size: 14px;">Options</div>
							<div v-for="(option, optionI) in field.options" :key="option + optionI" class="dF">
								<a-input class="mr-3 mb-2" v-model="option.option"></a-input>
								<div class="dF mb-2" style="width: 20%;">
									<a-icon class="mr-2" @click="field.options.splice(optionI + 1, 0, { option: 'New' });"
										style="margin-top: auto; margin-bottom: auto; color: var(--orange); cursor: pointer;"
										type="plus-circle" />
									<a-icon v-show="optionI > 1" @click="field.options.splice(optionI, 1)"
										style="margin-top: auto; margin-bottom: auto; color: var(--danger); cursor: pointer;"
										type="minus-circle" />
								</div>
							</div>
						</template>
					</template>

					<!-- Select Child -->
					<template v-else-if="field.type === 'child'">
						<h6 class="mb-3">General</h6>

						<!-- <div class="mb-2" style="font-size: 14px;">Field Type</div>
						<a-select :value="field.type" class="w-full" @change="(e) => updateFieldType(e, field)">
							<template v-for="(type, typeI) in allFieldTypes">
								<a-select-option v-if="type == 'name' || type == 'phone' || type == 'address'" :value="type"
									:key="type + typeI" :disabled="currentFields.includes(type)">
									{{ type.charAt(0).toUpperCase() + type.slice(1) }}
								</a-select-option>
								<template v-else>
									<template v-if="childInstances && childInstances.length">
										<a-select-option :value="type" :key="type + typeI">
											{{ type.charAt(0).toUpperCase() + type.slice(1) }}
										</a-select-option>
									</template>
									<template v-else>
										<a-select-option v-if="type != 'child'" :value="type" :key="type + typeI + typeI">
											{{ type.charAt(0).toUpperCase() + type.slice(1) }}
										</a-select-option>
									</template>
								</template>
							</template>
						</a-select> -->

						<div class="mb-2 mt-3" style="font-size: 14px;">Field Label</div>
						<a-input v-model="field.label">
						</a-input>
						<a-checkbox class="mt-3" :checked="field.required" @change="field.required = !field.required">
							Required
						</a-checkbox>
						<a-checkbox class="remove-leftmargin mt-3" :checked="field.includeEmail"
							@change="field.includeEmail = !field.includeEmail">
							Include in Email Notification
						</a-checkbox>
						<a-checkbox class="remove-leftmargin mt-3" :checked="field.selectAll"
							@change="field.selectAll = !field.selectAll">
							Enable Select All
						</a-checkbox>
						<a-checkbox class="remove-leftmargin mt-3" :checked="field.dropdown"
							@change="field.dropdown = !field.dropdown">
							Display as a dropdown?
						</a-checkbox>
						<template v-if="field.dropdown">
							<div class="mt-3 mb-2" style="font-size: 14px;">Placeholder</div>
								<a-input v-model="field.placeholder">
							</a-input>
						</template>

						<hr style="margin-top: 25px; margin-bottom: 25px;">


						<template v-if="field.type === 'child'">
							<div class="mt-3 mb-2" style="font-size: 14px;">Child Project(s)</div>
							<div :class="optionI != 0 ? 'mt-3' : ''" v-for="(option, optionI) in field.options"
								:key="option.id + optionI">
								<div>{{ option.label }}</div>
								<div class="dF aE">
									<a-input :placeholder="option.label" v-model="option.option" />
									<div class="ml-3 text-center">
										Active
										<a-checkbox :checked="option.active" @change="option.active = !option.active" />
									</div>
									<div class="ml-3 text-center">
										Default
										<a-checkbox :checked="option.selected"
											@change="option.selected = !option.selected" />
									</div>
								</div>
							</div>
						</template>
					</template>

					<!-- worksheet fields start-->

					<!-- Lot or Unit -->
					<template v-else-if="['lot', 'unit'].includes(field.type)">
						<h6 class="mb-3">General</h6>

						<div class="mb-2" style="font-size: 14px;">Field Label</div>
						<a-input v-model="field.label">
						</a-input>

						<hr style="margin-top: 25px; margin-bottom: 25px;">

						<a-checkbox :checked="field.showThirdChoice"
							@change="field.showThirdChoice = !field.showThirdChoice" :disabled="true">
							Show First {{ instance.productType === 'lowrise' ? 'Lot' : 'Unit' }} Choice
						</a-checkbox>

						<div class="mb-2 mt-3" style="font-size: 14px;">Field Label</div>
						<a-input v-model="field.label1">
						</a-input>
						<div class="mt-3 mb-2" style="font-size: 14px;">Placeholder</div>
						<a-input v-model="field.placeholder1">
						</a-input>
						<a-checkbox class="mt-3" :checked="field.required1" @change="field.required1 = !field.required1"
							:disabled="true">
							Required
						</a-checkbox>

						<hr style="margin-top: 25px; margin-bottom: 25px;">

						<a-checkbox :checked="field.showSecondChoice"
							@change="field.showSecondChoice = !field.showSecondChoice">
							Show Second {{ instance.productType === 'lowrise' ? 'Lot' : 'Unit' }} Choice
						</a-checkbox>

						<div v-if="field.showSecondChoice">
							<div class="mb-2 mt-3" style="font-size: 14px;">Field Label</div>
							<a-input v-model="field.label2">
							</a-input>
							<div class="mt-3 mb-2" style="font-size: 14px;">Placeholder</div>
							<a-input v-model="field.placeholder2">
							</a-input>
							<a-checkbox class="mt-3" :checked="field.required2"
								@change="field.required2 = !field.required2">
								Required
							</a-checkbox>
						</div>

						<hr style="margin-top: 25px; margin-bottom: 25px;">

						<a-checkbox :checked="field.showThirdChoice"
							@change="field.showThirdChoice = !field.showThirdChoice">
							Show Third {{ instance.productType === 'lowrise' ? 'Lot' : 'Unit' }} Choice
						</a-checkbox>

						<div v-if="field.showThirdChoice">
							<div class="mb-2 mt-3" style="font-size: 14px;">Field Label</div>
							<a-input v-model="field.label3">
							</a-input>
							<div class="mt-3 mb-2" style="font-size: 14px;">Placeholder</div>
							<a-input v-model="field.placeholder3">
							</a-input>
							<a-checkbox class="mt-3" :checked="field.required3"
								@change="field.required3 = !field.required3">
								Required
							</a-checkbox>
						</div>
					</template>

					<!-- Add Ons -->
					<template v-else-if="field.type === 'addOns'">
						<h6 class="mb-3">General</h6>

						<div class="mb-2" style="font-size: 14px;">Field Label</div>
						<a-input v-model="field.label">
						</a-input>

						<hr style="margin-top: 25px; margin-bottom: 25px;">

						<a-checkbox :checked="field.parking.show" @change="field.parking.show = !field.parking.show">
							Show Parking Choice
						</a-checkbox>

						<div class="mb-2 mt-3" style="font-size: 14px;">Field Label</div>
						<a-input v-model="field.parking.label">
						</a-input>
						<div class="mt-3 mb-2" style="font-size: 14px;">Placeholder</div>
						<a-input v-model="field.parking.placeholder">
						</a-input>
						<a-checkbox class="mt-3" :checked="field.parking.required"
							@change="field.parking.required = !field.parking.required">
							Required
						</a-checkbox>

						<a-checkbox class="mt-3" :checked="field.parking.allowMultiple"
							@change="field.parking.allowMultiple = !field.parking.allowMultiple">
							Allow Multiple
						</a-checkbox>

						<hr style="margin-top: 25px; margin-bottom: 25px;">

						<a-checkbox :checked="field.lockers.show" @change="field.lockers.show = !field.lockers.show">
							Show Locker Choice
						</a-checkbox>

						<div class="mb-2 mt-3" style="font-size: 14px;">Field Label</div>
						<a-input v-model="field.lockers.label">
						</a-input>
						<div class="mt-3 mb-2" style="font-size: 14px;">Placeholder</div>
						<a-input v-model="field.lockers.placeholder">
						</a-input>
						<a-checkbox class="mt-3" :checked="field.lockers.required"
							@change="field.lockers.required = !field.lockers.required">
							Required
						</a-checkbox>

						<a-checkbox class="mt-3" :checked="field.lockers.allowMultiple"
							@change="field.lockers.allowMultiple = !field.lockers.allowMultiple">
							Allow Multiple
						</a-checkbox>

						<hr style="margin-top: 25px; margin-bottom: 25px;">

						<a-checkbox :checked="field.bikeRacks.show" @change="field.bikeRacks.show = !field.bikeRacks.show">
							Show Bike Rack Choice
						</a-checkbox>

						<div class="mb-2 mt-3" style="font-size: 14px;">Field Label</div>
						<a-input v-model="field.bikeRacks.label">
						</a-input>
						<div class="mt-3 mb-2" style="font-size: 14px;">Placeholder</div>
						<a-input v-model="field.bikeRacks.placeholder">
						</a-input>
						<a-checkbox class="mt-3" :checked="field.bikeRacks.required"
							@change="field.bikeRacks.required = !field.bikeRacks.required">
							Required
						</a-checkbox>

						<a-checkbox class="mt-3" :checked="field.bikeRacks.allowMultiple"
							@change="field.bikeRacks.allowMultiple = !field.bikeRacks.allowMultiple">
							Allow Multiple
						</a-checkbox>
					</template>

					<!-- purchaser -->
					<template v-else-if="field.type === 'purchaser'">
						<h6 class="mb-3">General</h6>

						<div class="mb-2" style="font-size: 14px;">Field Label</div>
						<a-input v-model="field.label">
						</a-input>

						<a-checkbox class="mt-3" :checked="field.required" @change="field.required = !field.required"
							:disabled="field.index === 0">
							Required
						</a-checkbox>
					</template>

					<!-- solicitor -->
					<template v-else-if="field.type === 'solicitor'">
						<h6 class="mb-3">General</h6>

						<div class="mb-2" style="font-size: 14px;">Field Label</div>
						<a-input v-model="field.label"></a-input>

						<a-checkbox class="mt-3" :checked="field.required" @change="field.required = !field.required">
							Required
						</a-checkbox>
					</template>
				</div>
			</fieldBar>
		</div>
	</div>
</template>

<script>
import { formatDate } from 'bh-mod'
import FormModal from '@/components/forms/FormModal'
import BHLoading from 'bh-mod/components/common/Loading'
import fieldBar from '@/components/layout/fieldbar'
import draggable from 'vuedraggable'

export default {
	components: {
		BHLoading, FormModal, fieldBar, draggable
	},
	data() {
		return {
			formLoaded: false,
			templates: [],
			loadingField: false,
			allFieldTypes: ['header', 'text', 'number', 'dropdown', 'radio', 'checkbox', 'date', 'file', 'tag', 'child', 'purchaser', 'solicitor'],
			customFieldChoices: [
				{ includeEmail: false, type: 'text', label: 'Text Input', displayType: 'text', placeholder: '', required: false, default: '', icon: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-type mr-2"><polyline points="4 7 4 4 20 4 20 7"></polyline><line x1="9" y1="20" x2="15" y2="20"></line><line x1="12" y1="4" x2="12" y2="20"></line></svg>` },
				{ includeEmail: false, type: 'number', label: 'Number Input', displayType: 'number', placeholder: '', required: false, min: '', max: '', digitLength: '', decimal: 0, default: '', icon: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-hash mr-2"><line x1="4" y1="9" x2="20" y2="9"></line><line x1="4" y1="15" x2="20" y2="15"></line><line x1="10" y1="3" x2="8" y2="21"></line><line x1="16" y1="3" x2="14" y2="21"></line></svg>` },
				{ includeEmail: false, type: 'dropdown', label: 'Dropdown List', displayType: 'select', placeholder: '', required: false, selected: '', options: [{ option: 'Option 1' }, { option: 'Option 2' }, { option: 'Option 3' }], default: '', icon: `<svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 0.5rem;" width="20" height="20" viewBox="0 0 27 27"> <defs> <style> .cls-1 {fill: #3f3356; } .cls-2, .cls-4 {fill: none; } .cls-2 {stroke: #3f3356; stroke-width: 3px; } .cls-3 {stroke: none; } </style> </defs> <g id="Group_4524" data-name="Group 4524" transform="translate(1786 -7476)"> <path id="Path" class="cls-1" d="M2.174,0a1,1,0,0,0-.759,1.651L5.241,6.114a1,1,0,0,0,1.519,0l3.826-4.463A1,1,0,0,0,9.826,0Z" transform="translate(-1778 7486)"/> <g id="Rectangle_481" data-name="Rectangle 481" class="cls-2" transform="translate(-1786 7476)"> <rect class="cls-3" width="27" height="27" rx="3"/> <rect class="cls-4" x="1.5" y="1.5" width="24" height="24" rx="1.5"/> </g> </g> </svg>` },
				{ includeEmail: false, type: 'radio', label: 'Radio Button', displayType: 'radio', required: false, selected: 'Option 1', options: [{ option: 'Option 1' }, { option: 'Option 2' }, { option: 'Option 3' }], other: false, otherText: 'Other:', default: '', icon: `<svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 0.5rem;" width="20" height="20" viewBox="0 0 24.87 24.87"><path id="Icon_ionic-md-radio-button-on" data-name="Icon ionic-md-radio-button-on" class="radio-style" d="M15.81,9.592a6.217,6.217,0,1,0,6.217,6.217A6.236,6.236,0,0,0,15.81,9.592Zm0-6.217A12.435,12.435,0,1,0,28.245,15.81,12.472,12.472,0,0,0,15.81,3.375Zm0,22.383a9.948,9.948,0,1,1,9.948-9.948A9.977,9.977,0,0,1,15.81,25.758Z" transform="translate(-3.375 -3.375)"/> </svg>` },
				{ includeEmail: false, type: 'checkbox', label: 'Checkbox', displayType: 'checkbox', required: false, multiple: true, selected: 'Option 1', options: [{ option: 'Option 1' }, { option: 'Option 2' }, { option: 'Option 3' }], other: false, otherText: 'Other:', default: '', icon: `<svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 0.5rem;" width="20" height="20" viewBox="0 0 22.179 22.179"> <path id="Icon_material-check-box" data-name="Icon material-check-box" class="cls-1" d="M24.215,4.5H6.964A2.464,2.464,0,0,0,4.5,6.964v17.25a2.464,2.464,0,0,0,2.464,2.464h17.25a2.464,2.464,0,0,0,2.464-2.464V6.964A2.464,2.464,0,0,0,24.215,4.5ZM13.125,21.75,6.964,15.59,8.7,13.852l4.424,4.411,9.352-9.352,1.737,1.75Z" transform="translate(-4.5 -4.5)"/> </svg>` },
				{ includeEmail: false, type: 'date', label: 'Date/Time', displayType: 'date', placeholder: '', required: false, dateFormat: 'YYYY/MM/DD', timeFormat: 'HH:mm', timeEnable: false, default: '', icon: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar mr-2"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>` },
				{ includeEmail: false, type: 'file', label: 'File Attachment', displayType: 'file', buttonLabel: 'Upload', maxSize: 10, placeholder: '', format: 'any', required: false, default: '', icon: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload mr-2"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg>` },
				{ includeEmail: false, type: 'tag', label: 'Tag Field', displayType: 'checkbox', placeholder: '', multiple: false, required: false, selectAll: false, dropdown: false, selected: '', options: [{ option: 'Option 1' }, { option: 'Option 2' }, { option: 'Option 3' }], default: '', icon: `<svg style="margin-top 10px;" height="20" width="20" viewBox="64 64 896 896" data-icon="tag" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" class="mr-2"><path d="M938 458.8l-29.6-312.6c-1.5-16.2-14.4-29-30.6-30.6L565.2 86h-.4c-3.2 0-5.7 1-7.6 2.9L88.9 557.2a9.96 9.96 0 0 0 0 14.1l363.8 363.8c1.9 1.9 4.4 2.9 7.1 2.9s5.2-1 7.1-2.9l468.3-468.3c2-2.1 3-5 2.8-8zM459.7 834.7L189.3 564.3 589 164.6 836 188l23.4 247-399.7 399.7zM680 256c-48.5 0-88 39.5-88 88s39.5 88 88 88 88-39.5 88-88-39.5-88-88-88zm0 120c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32z"></path></svg>` },
				{ includeEmail: false, type: 'purchaser', label: 'Purchaser', displayType: 'purchaser', placeholder: '', required: false, icon: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" style="margin-right: 0.5rem;" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>` },
				{ includeEmail: false, type: 'solicitor', label: 'Purchaser\'s Lawyer', displayType: 'solicitor', placeholder: '', required: false, icon: `<svg width="20" height="20" style="margin-right: 0.5rem;" viewBox="-7 0 47.005 47.005" xmlns="http://www.w3.org/2000/svg" fill="currentColor"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g transform="translate(-830.977 -118.86)"> <path d="M848.477,157.36H832.72c-.451,0-1.243-.692-1.243-1.143V119.852c0-.45.792-.492,1.243-.492h29.816c.45,0,.941.042.941.492v36.365c0,.451-.491,1.143-.941,1.143h-1.059" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"></path> <circle cx="5.91" cy="5.91" r="5.91" transform="translate(848.394 150.162)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"></circle> <path d="M856.477,161.36v3.667c0,.235-.017.392-.231.294l-2.04-.982a.2.2,0,0,0-.211,0l-2.108.982a.289.289,0,0,1-.41-.294V161.36" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"></path> <line x2="13" transform="translate(841.477 146.36)" stroke-width="1" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" fill="none"></line> <line x2="19" transform="translate(838.477 138.36)" stroke-width="1" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" fill="none"></line> <line x2="19" transform="translate(838.477 142.36)" stroke-width="1" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" fill="none"></line> <line x2="19" transform="translate(838.477 130.36)" stroke-width="1" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" fill="none"></line> <line x2="19" transform="translate(838.477 126.36)" stroke-width="1" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" fill="none"></line>  </g> </g></svg>` }
			],
			headerChoices: [
				{ type: 'header', label: 'Header', description: '', icon: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-type mr-2"><polyline points="4 7 4 4 20 4 20 7"></polyline><line x1="9" y1="20" x2="15" y2="20"></line><line x1="12" y1="4" x2="12" y2="20"></line></svg>` },
			],
			fieldSelect: false,
			selectedField: '',
			fieldHovering: '',
			childOptions: [],

			mailOpen: '',
			filter: 'all',
			baseImage: '',
			loadModal: false,
			taskOpen: '',
			appointmentOpen: '',
			searchMode: false,
			specificForms: [],
			worksheetFields: [
				{
					id: Date.now() + 1,
					hide: false,
					type: 'unit',
					label: 'Unit Choice(s)',
					label1: 'Unit Choice #1',
					placeholder1: 'Select unit choice #1',
					required1: true,
					showSecondChoice: true,
					label2: 'Unit Choice #2',
					placeholder2: 'Select unit choice #2',
					required2: false,
					showThirdChoice: true,
					label3: 'Unit Choice #3',
					placeholder3: 'Select unit choice #3',
					required3: false,
					options: [],
				},
				{
					id: Date.now() + 2,
					hide: false,
					type: 'addOns',
					label: 'Add Ons',
					parking: {
						show: true,
						label: 'Parking Spot',
						placeholder: 'Select Parking Spot',
						required: false,
						allowMultiple: true,
						options: [],
					},
					lockers: {
						show: true,
						label: 'Locker',
						placeholder: 'Select Locker',
						required: false,
						allowMultiple: true,
						options: [],
					},
					bikeRacks: {
						show: true,
						label: 'Bike Rack',
						placeholder: 'Select Bike Rack',
						required: false,
						allowMultiple: true,
						options: [],
					},
				},
				{
					id: Date.now() + 3,
					hide: false,
					type: 'purchaser',
					label: 'Primary Purchaser',
					required: true,
					idType: 'pp',
					index: 0
				},
				{
					id: Date.now() + 4,
					hide: false,
					type: 'solicitor',
					label: `Purchaser's Lawyer`,
					required: false
				}
			],
			worksheetFieldsLr: [
				{
					id: Date.now() + 1,
					hide: false,
					type: 'lot',
					label: 'Lot Choice(s)',
					label1: 'Lot Choice #1',
					placeholder1: 'Select lot choice #1',
					elevationLabel1: 'Elevation Choice #1',
					elevationPlaceholder1: 'Select elevation choice #1',
					required1: true,
					showSecondChoice: true,
					label2: 'Lot Choice #2',
					placeholder2: 'Select lot choice #2',
					elevationLabel2: 'Elevation Choice #2',
					elevationPlaceholder2: 'Select elevation choice #2',
					required2: false,
					showThirdChoice: true,
					label3: 'Lot Choice #3',
					placeholder3: 'Select lot choice #3',
					elevationLabel3: 'Elevation Choice #3',
					elevationPlaceholder3: 'Select elevation choice #3',
					required3: false,
					options: [],
				},
				{
					id: Date.now() + 2,
					hide: false,
					type: 'purchaser',
					label: 'Primary Purchaser',
					required: true,
					idType: 'pp',
					index: 0
				},
				{
					id: Date.now() + 3,
					hide: false,
					type: 'solicitor',
					label: `Purchaser's Lawyer`,
					required: false
				}
			],
			senders: [],
			form: {
				leadScore: 1,
				name: '',
				type: '',
				group:'',
				url: '',
				instance: this.$store.state.instance.id,
				tags: [],
				fields: [
					{
						type: 'email',
						id: Date.now() + '',
						label: 'Email',
						placeholder: '',
						required: true
					},
					{
						id: Date.now() + 1,
						hide: false,
						type: 'name',
						label: 'Name',
						required: false,
						middleInitial: false,
						prefix: false,
						suffix: false,
						icon: `<svg xmlns="http://www.w3.org/2000/svg" style="margin-top: 10px;" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user mr-2"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>`
					},
					{
						id: Date.now() + 2,
						hide: false,
						type: 'phone',
						label: 'Phone Number',
						placeholder: '',
						required: false,
						min: '',
						max: '',
						digitLength: '',
						decimal: 0,
						format: 'National',
						country: '',
						default: '',
						icon: `<svg xmlns="http://www.w3.org/2000/svg" style="margin-top: 10px;" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone mr-2"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>`
					},
					{
						id: Date.now() + 3,
						hide: false,
						type: 'address',
						label: 'Address',
						required: false,
						country: '',
						options: {
							addressOne: true,
							addressTwo: true,
							city: true,
							province: true,
							postal: true,
							country: false
						},
						icon: `<svg xmlns="http://www.w3.org/2000/svg" style="margin-top: 10px;" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin mr-2"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>`
					},
					{
						id: Date.now() + 4,
						hide: false,
						type: 'isAgent',
						displayType: 'checkbox',
						label: 'Are you a realtor?',
						required: false,
						default: '',
						options: [
							{ option: 1 },
							{ option: 0 }
						],
						icon: `<svg xmlns="http://www.w3.org/2000/svg" style="margin-top: 10px;" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin mr-2"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>`
					}
				],
				lastRegistered: 0,
				users: [],
				options: {
					feedChildren: true,
					submitLabel: 'Submit',
					thanks: {
						headline: '',
						text: ''
					},
					supporting: '',
					consent: {
						enabled: true,
						text: 'I consent.'
					},
					smsConsent: {
						enabled: false,
						text: 'By submitting this form and signing up for texts, you consent to receive marketing text messages from [company name] at the number provided. Consent is not a condition of purchase. Msg & data rates may apply.'
					},
					autoresponder: '',
					autoresponderSubject: '',
					autoresponderPreheader: '',
					fromEmail: '',
					serveAgents: ['all']
				}
			},
			selectedForm: {},
			purchaserLabel: ['Primary Purchaser', 'Secondary Purchaser'],
			currentWidth: window.innerWidth,
			formGroups:[],
			filteredGroups:[]
		}
	},
	watch: {
		save() {
			if (this.$route.params.id) {
				return this.updateForm()
			}
			return this.createForm()
		},
		preview() {
			if (this.$route.params.id) {
				this.saveAndPreview()
			} else {
				this.createAndPreview()
			}
		},
		searchTimeStamp() {
			this.$store.dispatch('DO_SEARCH')
		},
		currentPage() {
			this.$store.dispatch('DO_SEARCH')
		},
		formType() {

			if (this.formType == 'Add New Form' || this.formType == 'Edit Form') {
				this.$store.commit('CLOSE_RIGHT_BAR')
				this.loadingPage = true
				this.$api.get(`/projects/:instance/?default=true`).then(({ data }) => {
					this.templates = data
					this.loadingPage = false
				}).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
			}
		},
		rightBar: {
			handler(val) {
				if (val) {
					setTimeout(() => {
						var elem = document.querySelector(".field-selected");
						if (elem != null) {
							elem.scrollIntoView();
						}
					}, 100)
				}
			},
			deep: true
		}
	},
	computed: {
		prevRoute() {
			return this.$store.state.prevRoute
		},
		childInstances() {
			return this.$store.state.instance.children
		},
		rightBar() {
			return this.$store.state.rightbar
		},
		search() {
			return this.$store.state.search.form
		},
		save() {
			return this.$store.state.save
		},
		preview() {
			return this.$store.state.forms.preview.prev
		},
		generateIcons() {
			let obj = {
				email: '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>',
				name: '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>',
				phone: '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>',
				address: '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>',
				isAgent: '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-check"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><polyline points="17 11 19 13 23 9"></polyline></svg>',
				text: '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-type"><polyline points="4 7 4 4 20 4 20 7"></polyline><line x1="9" y1="20" x2="15" y2="20"></line><line x1="12" y1="4" x2="12" y2="20"></line></svg>',
				number: '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-hash"><line x1="4" y1="9" x2="20" y2="9"></line><line x1="4" y1="15" x2="20" y2="15"></line><line x1="10" y1="3" x2="8" y2="21"></line><line x1="16" y1="3" x2="14" y2="21"></line></svg>',
				dropdown: '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 27 27"> <defs> <style> .cls-1 {fill: #3f3356; } .cls-2, .cls-4 {fill: none; } .cls-2 {stroke: #3f3356; stroke-width: 3px; } .cls-3 {stroke: none; } </style> </defs> <g id="Group_4524" data-name="Group 4524" transform="translate(1786 -7476)"> <path id="Path" class="cls-1" d="M2.174,0a1,1,0,0,0-.759,1.651L5.241,6.114a1,1,0,0,0,1.519,0l3.826-4.463A1,1,0,0,0,9.826,0Z" transform="translate(-1778 7486)"/> <g id="Rectangle_481" data-name="Rectangle 481" class="cls-2" transform="translate(-1786 7476)"> <rect class="cls-3" width="27" height="27" rx="3"/> <rect class="cls-4" x="1.5" y="1.5" width="24" height="24" rx="1.5"/> </g> </g> </svg>',
				radio: '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24.87 24.87"><path id="Icon_ionic-md-radio-button-on" data-name="Icon ionic-md-radio-button-on" d="M15.81,9.592a6.217,6.217,0,1,0,6.217,6.217A6.236,6.236,0,0,0,15.81,9.592Zm0-6.217A12.435,12.435,0,1,0,28.245,15.81,12.472,12.472,0,0,0,15.81,3.375Zm0,22.383a9.948,9.948,0,1,1,9.948-9.948A9.977,9.977,0,0,1,15.81,25.758Z" transform="translate(-3.375 -3.375)"/> </svg>',
				checkbox: '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 22.179 22.179"> <path id="Icon_material-check-box" data-name="Icon material-check-box" class="cls-1" d="M24.215,4.5H6.964A2.464,2.464,0,0,0,4.5,6.964v17.25a2.464,2.464,0,0,0,2.464,2.464h17.25a2.464,2.464,0,0,0,2.464-2.464V6.964A2.464,2.464,0,0,0,24.215,4.5ZM13.125,21.75,6.964,15.59,8.7,13.852l4.424,4.411,9.352-9.352,1.737,1.75Z" transform="translate(-4.5 -4.5)"/> </svg>',
				date: '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>',
				file: `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg>`,
				header: '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-type"><polyline points="4 7 4 4 20 4 20 7"></polyline><line x1="9" y1="20" x2="15" y2="20"></line><line x1="12" y1="4" x2="12" y2="20"></line></svg>',
				tag: '<svg height="20" width="20" viewBox="64 64 896 896" data-icon="tag" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" class=""><path d="M938 458.8l-29.6-312.6c-1.5-16.2-14.4-29-30.6-30.6L565.2 86h-.4c-3.2 0-5.7 1-7.6 2.9L88.9 557.2a9.96 9.96 0 0 0 0 14.1l363.8 363.8c1.9 1.9 4.4 2.9 7.1 2.9s5.2-1 7.1-2.9l468.3-468.3c2-2.1 3-5 2.8-8zM459.7 834.7L189.3 564.3 589 164.6 836 188l23.4 247-399.7 399.7zM680 256c-48.5 0-88 39.5-88 88s39.5 88 88 88 88-39.5 88-88-39.5-88-88-88zm0 120c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32z"></path></svg>',
				child: '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-link"><path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path></svg>',
				unit: '<svg fill="#000000" width="24" height="24" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title></title> <g id="Office"> <path d="M42,14H22a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1H42a1,1,0,0,0,1-1V15A1,1,0,0,0,42,14ZM23,16h8v4H23Zm18,4H33V16h8Z"></path> <path d="M42,26H22a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1H42a1,1,0,0,0,1-1V27A1,1,0,0,0,42,26ZM23,28h8v4H23Zm18,4H33V28h8Z"></path> <path d="M30,48.5a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0v-1A1,1,0,0,0,30,48.5Z"></path> <path d="M34,48.5a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0v-1A1,1,0,0,0,34,48.5Z"></path> <path d="M60,59H54V8a1,1,0,0,0-1-1H11a1,1,0,0,0-1,1V59H4a1,1,0,0,0,0,2H60a1,1,0,0,0,0-2ZM23,59V41H41V59Zm20,0V40a1,1,0,0,0-1-1H22a1,1,0,0,0-1,1V59H12V9H52V59Z"></path> </g> </g></svg>',
				addOns: '<svg fill="#000000" width="18" height="18" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M16 0c-8.836 0-16 7.163-16 16s7.163 16 16 16c8.837 0 16-7.163 16-16s-7.163-16-16-16zM16 30.032c-7.72 0-14-6.312-14-14.032s6.28-14 14-14 14 6.28 14 14-6.28 14.032-14 14.032zM23 15h-6v-6c0-0.552-0.448-1-1-1s-1 0.448-1 1v6h-6c-0.552 0-1 0.448-1 1s0.448 1 1 1h6v6c0 0.552 0.448 1 1 1s1-0.448 1-1v-6h6c0.552 0 1-0.448 1-1s-0.448-1-1-1z"></path> </g></svg>',
				purchaser: '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>',
				solicitor: '<svg width="20" height="20" viewBox="-7 0 47.005 47.005" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g transform="translate(-830.977 -118.86)"> <path d="M848.477,157.36H832.72c-.451,0-1.243-.692-1.243-1.143V119.852c0-.45.792-.492,1.243-.492h29.816c.45,0,.941.042.941.492v36.365c0,.451-.491,1.143-.941,1.143h-1.059" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"></path> <circle cx="5.91" cy="5.91" r="5.91" transform="translate(848.394 150.162)" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"></circle> <path d="M856.477,161.36v3.667c0,.235-.017.392-.231.294l-2.04-.982a.2.2,0,0,0-.211,0l-2.108.982a.289.289,0,0,1-.41-.294V161.36" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"></path> <line x2="13" transform="translate(841.477 146.36)" stroke-width="1" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" fill="none"></line> <line x2="19" transform="translate(838.477 138.36)" stroke-width="1" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" fill="none"></line> <line x2="19" transform="translate(838.477 142.36)" stroke-width="1" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" fill="none"></line> <line x2="19" transform="translate(838.477 130.36)" stroke-width="1" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" fill="none"></line> <line x2="19" transform="translate(838.477 126.36)" stroke-width="1" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" fill="none"></line>  </g> </g></svg>'
			}
			return obj
		},
		users() {
			return this.$store.state.forms.crmUsers.map(x => {
				if (!x.firstName) x.firstName = '';
				if (!x.lastName) x.lastName = '';
				if (x.id === this.$store.state.user.user.id && !x.lastName?.includes("(You)")) x.lastName += ' (You)'
				return x
			})
		},
		realtors() {
			return this.$store.state.forms.realtors
		},
		currentForm() {
			return this.$store.state.currentForm
		},
		formType() {
			return this.$store.state.formType
		},
		forms() {
			return Object.values(this.$store.state.forms.allForms)
		},
		searchTimeStamp() {
			return this.$store.state.forms.searchTimeStamp
		},
		currentPage: {
			get() {
				return this.$store.state.forms.currentPage
			},
			set(val) {
				this.$store.commit('SET_CONTACTS_PAGE', val)
			}
		},
		tags() {
			let result = {};
			Object.values(this.$store.state.forms.tags).forEach(tag => {
				if (!result[tag.name]) {
					result[tag.name] = tag
				}
			})
			return Object.values(result)
		},
		origTags() {
			return this.$store.state.forms.tags
		},
		resultLength() {
			return this.$store.state.forms.resultLength
		},
		showingContactMode() {
			return this.listMode === 'all' ? `Showing All Contacts (${this.resultLength} Contacts) ` : `Found ${this.resultLength} Contacts `
		},
		newStoreTime() {
			return this.$store.state.forms.newStoreTime
		},
		instance() {
			return this.$store.state.instance
		},
		pageSize() {
			return this.$store.state.forms.pageSize
		},
		query() {
			return this.$store.getters.query
		},
		loadingPage: {
			get() {
				return this.$store.state.forms.loadingPage
			},
			set(val) {
				this.$store.commit('LOAD_PAGE', val)
			}
		},
		dialog() {
			return this.$store.state.forms.formDrawer
		},
		currentFields() {
			let l = []
			if (this.form && this.form.fields.length) {
				this.form.fields.forEach(x => {
					if (x.type == 'name' || x.type == 'phone' || x.type == 'address') {
						l.push(x.type)
					}
				})
			}
			return l
		},
	},
	methods: {
		selectedGroup(e){
			console.log('SELECTED GROUP', e)
			this.form.group = e[e.length - 1]
		},
		handleGroupSearch(value){
			const inputValue = value.toLowerCase();

			this.filteredGroups = this.formGroups.filter(group =>
				group.name.toLowerCase().includes(inputValue)
			);
		},
		tagsToIds(obj) {
			let tags = obj.tags || []
			obj.newTags = []
			let oldTags = []
			let self = this

			tags.forEach(tID => {
				if (self.tags.find(x => x.name === tID)) {
					let find = self.tags.find(x => x.name == tID)
					oldTags.push(find.id)
				}
				else obj.newTags.push(tID)
			})
			obj.tags = oldTags

			return obj

		},
		saveAndPreview() {
			if (this.hasErrors()) return
			this.loadingPage = true
			let sendObj = this.package()


			this.$api.put(`/forms/${this.instance.id}/${sendObj.id}`, sendObj).then(({ data }) => {
				for (var i = 0; i < this.forms.length; i++) {
					if (this.forms[i].id == data.id) {
						this.forms[i] = data
					}
				}
				this.onClose2({ type: 'edit', data })
				this.$router.push(`/preview/${data.id}`)
				this.loadingPage = false
			}).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
		},
		createAndPreview() {
			if (this.hasErrors()) return
			this.loadingPage = true
			let sendObj = this.package()

			this.$api.post(`/forms/${this.instance.id}`, sendObj).then(({ data }) => {
				this.onClose2({ type: 'add', data })
				this.$router.push(`/preview/${data.id}`)

				this.loadingPage = false
			}).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
		},
		goBack() {
			this.$router.push(this.prevRoute)
		},

		copyField(field, fieldI) {
			let start = fieldI + 1
			let arr = this.form.fields
			let newObj = JSON.parse(JSON.stringify(field))
			newObj.id = Date.now() + 'XXX'
			arr.splice(start, 0, newObj)
			this.form.fields = arr
			this.$store.commit('OPEN_RIGHT_BAR', newObj.id)
		},
		hideField(fieldI) {
			if (this.form.fields[fieldI].hide == true) {
				this.form.fields[fieldI].hide = false
			} else {
				this.form.fields[fieldI].hide = true
			}
		},
		updateFieldType(type, field) {
			let index = this.form.fields.findIndex(x => x.id == field.id)
			let isCustom = false;
			let obj = {};

			if (type == 'text' || type == 'number' || type == 'dropdown' || type == 'radio' || type == 'checkbox' || type == 'date' || type == 'file' || type == 'tag' || type == 'child' || type == 'purchaser' || type == 'solicitor') {
				isCustom = true
			}

			if (isCustom) {
				let customField = this.customFieldChoices.find(x => x.type === type)
				obj = { ...customField }
			} else {
				let headerField = this.headerChoices.find(x => x.type === type)
				obj = { ...headerField }
			}

			obj.id = Date.now() + 'XXX'
			let newArray = this.form.fields
			newArray[index] = obj
			this.form.fields = []
			this.form.fields = this.form.fields.concat(newArray)
			this.$store.commit('OPEN_RIGHT_BAR', obj.id)
		},
		endMove() {
		},
		async createForm() {
			if (this.hasErrors()) return
			let sendObj = this.package()
			let nameExist = false
			await this.forms.forEach(x => {
				if (x.name.trim('').toLowerCase() == sendObj.name.trim('').toLowerCase()) {
					nameExist = true
					return
				}
			})

			if (nameExist) {
				let self = this
				this.$confirm({
					title: 'Name already exists',
					content: h => <div>This form name already exists. Do you still want to proceed?</div>,
					okText: 'Save',
					cancelText: 'Cancel',
					centered: true,
					onOk() {
						self.loadingPage = true
						self.$api.post(`/forms/${self.instance.id}`, sendObj).then(({ data }) => {
							self.form.id = data.id
							self.onClose2({ type: 'add', data })
							self.$message.success('Form Saved')

							self.$router.push(`/edit/${data.id}`)

							self.loadingPage = false
						}).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								self.$message.error(self.$err(err))
							}
						})
					},
					onCancel() {
						self.loadingPage = false
					}
				})
			} else {
				this.loadingPage = true
				this.$api.post(`/forms/${this.instance.id}`, sendObj).then(({ data }) => {
					this.form.id = data.id
					this.onClose2({ type: 'add', data })
					this.$message.success('Form Saved')

					this.$router.push(`/edit/${data.id}`)

					this.loadingPage = false
				}).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
			}
		},
		async updateForm() {
			if (this.hasErrors()) return

			let sendObj = this.package()
			let nameExist = false
			this.forms.forEach(x => {
				if (x.id != this.$route.params.id && x.name.trim('').toLowerCase() == sendObj.name.trim('').toLowerCase()) {
					nameExist = true
					return
				}
			})

			if (nameExist) {
				let self = this
				this.$confirm({
					title: 'Name already exists',
					content: h => <div>This form name already exists. Do you still want to proceed?</div>,
					okText: 'Save',
					cancelText: 'Cancel',
					centered: true,
					onOk() {
						self.loadingPage = true
						self.$api.put(`/forms/${self.instance.id}/${self.$route.params.id}`, sendObj).then(({ data }) => {
							for (var i = 0; i < self.forms.length; i++) {
								if (self.forms[i].id == data.id) {
									self.forms[i] = data
								}
							}
							self.onClose2({ type: 'edit', data })
							self.$message.success('Form Saved')
							self.loadingPage = false
						}).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								self.$message.error(self.$err(err))
							}
						})
					},
					onCancel() {
						self.loadingPage = false
					}
				})
			} else {
				this.loadingPage = true
				this.$api.put(`/forms/${this.instance.id}/${this.$route.params.id}`, sendObj).then(({ data }) => {
					for (var i = 0; i < this.forms.length; i++) {
						if (this.forms[i].id == data.id) {
							this.forms[i] = data
						}
					}
					this.onClose2({ type: 'edit', data })
					this.$message.success('Form Saved')
					this.loadingPage = false
				}).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
			}

		},
		package() {
			let sendObj = JSON.parse(JSON.stringify(this.form))

			sendObj = this.tagsToIds(sendObj)
			if (sendObj.options.serveAgents && sendObj.options.serveAgents[0] === "all") {
				sendObj.options.serveAgents = [];
			}
			return sendObj
		},
		basicCheck(field) {
			let obj = false
			if (this.form && this.form.fields.length) {
				this.form.fields.forEach(x => {
					if (x.type === field.type) obj = true
				})
			}
			return obj
		},
		editField(field) {
			this.$store.commit('OPEN_RIGHT_BAR', field.id)
		},
		deleteField(field, fieldI) {
			let self = this
			this.$confirm({
				title: 'Delete Field?',
				content: h => <div>Do you want to delete this field?</div>,
				okText: 'Delete',
				okType: 'danger',
				cancelText: 'Cancel',
				centered: true,
				onOk() {
					self.form.fields.splice(fieldI, 1)
					self.$store.commit('CLOSE_RIGHT_BAR')
				},
			})
		},
		getCurrentStyle(current) {
			const style = {};
			if (current.date() === 1) {
				style.border = '1px solid #1890ff';
				style.borderRadius = '50%';
			}
			return style;
		},
		submitField() {

			let isCustom = this.customFieldChoices.find(x => x.type === this.selectedField)
			let obj = false

			if (isCustom) {
				obj = this.customFieldChoices.find(x => x.type === this.selectedField)
			} else {
				obj = this.headerChoices.find(x => x.type === this.selectedField)
			}

			if (obj.type === 'solicitor') {
				const solicitorField = this.form.fields.find(field => field.type === 'solicitor');
				if (solicitorField) {
					return this.$message.warn('Purchaser\'s Lawyer field is already exists in current form.')
				}
			}

			if (!obj) return null

			obj = JSON.parse(JSON.stringify(obj))
			obj.id = Date.now() + 'XXX'

			if (obj.type === 'purchaser') {
				const purchaserFields = this.form.fields.filter(field => field.type === 'purchaser');
				obj.index = purchaserFields.length;
				obj.label = this.purchaserLabel[obj.index] || `Purchaser ${obj.index + 1}`
			}

			this.form.fields.push(obj)
			this.fieldSelect = false
			this.selectedField = ''
			this.$store.commit('OPEN_RIGHT_BAR', obj.id)
		},
		formatDate,
		onClose() {
			this.fieldSelect = false
		},
		onClose2({ type = '', data = {} }) {
			this.$store.dispatch('CLOSE_FORM_DRAWER', { type, data })
		},
		hasErrors() {
			if (!this.form.name.trim()) return this.$message.error('Form needs a name'), true
		},


		filterOption(input, option) {
			return (
				option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
			);
		},

		updateWidth() {
			this.currentWidth = window.innerWidth;
		},

		onRealtorChange(realtors) {
			realtors = realtors[realtors.length - 1];
			if (realtors && realtors != "all") {
				let index = this.form.options.serveAgents.findIndex((x) => x == "all");
				if (index > -1) {
					this.form.options.serveAgents.splice(index, 1);
				}
			} else {
				this.form.options.serveAgents = ["all"];
			}
			if (this.form.options.serveAgents.length == 0) {
				this.form.options.serveAgents = ["all"];
			}
		}
	},
	mounted() {
		window.addEventListener('resize', this.updateWidth)
		let instanceIds = []
		let options = []
		if (this.childInstances && this.childInstances.length) {
			this.childInstances.forEach(instance => {
				let obj = {
					label: instance.name,
					id: instance.id,
					active: true,
					selected: true,
					option: instance.name
				}
				options.push(obj)
				instanceIds.push(instance.id)
			})

			let childField = { includeEmail: false, type: 'child', label: 'Select Children', displayType: 'checkbox', placeholder: '', required: false, selectAll: false, dropdown: false, hide: true, selected: '', options: this.childOptions, default: '', icon: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-link mr-2"><path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path></svg>` }

			childField.options = options
			let childObj = JSON.parse(JSON.stringify(childField))
			childObj.id = Date.now() + 'XXX'
			let hasChild = this.form.fields.find(x => x.type == 'child')
			if (!hasChild) {
				this.form.fields.push(childObj)
			}
		} else {
			let hasChild = this.form.fields.find(x => x.type == 'child')
			if (hasChild) {
				this.form.fields = this.form.fields.filter(x => x.type != 'child')
			}
		}

		this.form.fields.forEach(x => {
			if (x.type == 'child') {
				let ids = []
				x.options.forEach(y => {
					ids.push(y.id)
				})
				let diff = instanceIds.filter(x => !ids.includes(x))
				if (diff && diff.length) {
					diff.forEach(id => {
						let instance = this.childInstances.find(x => x.id == id)
						let obj = {
							label: instance.name,
							id: instance.id,
							active: false,
							selected: false,
							option: instance.name
						}
						x.options.push(obj)
					})
				}
			}
		})

		if (this.form.options && (!this.form.options.serveAgents || !this.form.options.serveAgents.length)) {
			this.form.options.serveAgents = ['all']
		}
	},
	async created() {
		this.$store.dispatch('FETCH_REALTORS')
		if (this.$route.params.id) {
			this.$store.commit('FORM_TYPE', { value: 'Edit Form' })

			let form = this.forms.find(x => x.id == this.$route.params.id)

			if (form) {
				if (form && form.tags && form.tags.length) {
					let tags = []
					form.tags.forEach(tag => {
						if (tag.id) tag = tag.id
						if (this.origTags[tag]) {
							tags.push(this.origTags[tag].name)
						}
					})
					form.tags = tags
				}
				if (form && form.users && form.users.length) {
					form.users = form.users.map(x => {
						if (x instanceof Object) return x.id
						else return x
					})
				}
				if (form && form.group && form.group.id) {
					form.group = form.group.id
				}

				form.fields.forEach(x => {
					if (!x.displayType) {
						if (x.type == 'text') x.displayType = 'text'
						else if (x.type == 'number') x.displayType = 'number'
						else if (x.type == 'dropdown') x.displayType = 'select'
						else if (x.type == 'radio') x.displayType = 'radio'
						else if (x.type == 'checkbox') x.displayType = 'checkbox'
						else if (x.type == 'date') x.displayType = 'date'
						else if (x.type == 'file') x.displayType = 'file'
						else if (x.type == 'tag') x.displayType = 'checkbox'
						else if (x.type == 'child') x.displayType = 'checkbox'
					}
				})
				this.form = form

				if (!this.form.tags) {
					this.form.tags = [];
				}
			}
		} else {
			this.$store.commit('FORM_TYPE', { value: 'Add New Form' })
			this.form = this.currentForm

			if (this.form.type === 'worksheet') {
				if (this.instance.productType == 'highrise'){
					this.form.fields = this.worksheetFields;
				} else if (this.instance.productType == 'lowrise'){
					this.form.fields = this.worksheetFieldsLr;
				}
			}
		}

		if (this.form.type !== 'worksheet') {
			this.$api.get(`/senders/:instance`).then(({ data }) => {
				this.senders = data
			}).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
		}

		this.loadingPage = true
		try {
			let { data } = await this.$api.get('/groups/:instance')
			console.log('DATA COMING BACK', data)
			this.formGroups = JSON.parse(JSON.stringify(data))
			this.filteredGroups = JSON.parse(JSON.stringify(data))
			this.loadingPage = false
		} catch (err) {
			this.loadingPage = false
			if (!err || !err.response || !err.response.status || err.response.status !== 400) {
				this.$message.error(this.$err(err))
			}
		}

		if (!this.form.options.hasOwnProperty('smsConsent')) {
			this.form.options.smsConsent = {
				enabled: false,
				text: 'By submitting this form and signing up for texts, you consent to receive marketing text messages from [company name] at the number provided. Consent is not a condition of purchase. Msg & data rates may apply.'
			}
		}
		this.formLoaded = true;
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.updateWidth)
	},
}
</script>
<style lang="scss" module>@import "./style.module.scss";
</style>
<style lang="scss" scoped>
@media screen and (min-width: 1250px) {
	.responsiveFlex {
		display: flex;
		gap: 1.25em;
	}

	.leftSide {
		width: 75%;
	}

	.rightSide {
		width: 25%;
	}
}

@media screen and (max-width: 1250px) {
	.responsiveFlex {
		display: block;
	}

	.leftSide {
		width: 100%;
	}

	.rightSide {
		width: 100%;
	}
}

.edit-delete {
	cursor: pointer;
	color: rgba(0, 0, 0, 0.65);
	transition: color .1s ease-in;
}

.edit-delete:hover {
	color: orange;
	transition: color .1s ease-in;
}

.radio-style {
	fill: #3f3356;
}

.addField {
	width: 40px;
	height: 40px;
	border-radius: 40px;
	background-color: var(--orange);
	line-height: 44px;
	text-align: center;
	color: white;
	font-size: 20px;
	margin: 24px auto 0 auto;
	cursor: pointer;
}

.fieldOptions {
	width: 100%;
	height: 45px;
	line-height: 45px;
}

.fieldCard {
	box-shadow: 0px 1px 3px rgba(63, 63, 68, 0.15);

	&:hover {
		cursor: pointer;
		border-color: var(--orange);
	}
}

.contacts-page {
	@media screen and (min-width:567px) {
		max-width: calc(100% - 35px);
	}
}

.aB {
	align-items: baseline;
}

.more-option-icon {
	width: 35px;
	height: 35px;
	border-radius: 35px;
	text-align: center;
	background-color: transparent;
}

.more-option-icon:hover {
	color: var(--orange) !important;
	background-color: var(--light-gray);
}

.popoverContent {
	height: 35px;
	width: 160px;
	line-height: 35px;
	padding-left: 10px;
}

.popoverContent:hover {
	background-color: var(--off-white-light);
	cursor: pointer;
}
</style>

<style lang="scss">
.remove-leftmargin {
	margin-left: 0 !important;
}

.ps__thumb-x {
	display: none;
}

.popoverStyle .ant-popover-inner-content {
	padding: 0;
	background-color: white;
	border-radius: 20px;
}

.tableStyle .ant-table-thead>tr>th {
	background-color: white;
}

.rightForm {
	.lead .ant-card-head-wrapper {
		width: 100px;
	}

	.feedLead .ant-card-head-wrapper {
		width: 175px;
	}

	.autoresponder .ant-card-head-wrapper {
		width: 130px;
	}

	.tags .ant-card-head-wrapper {
		width: 195px;
	}

	.notification .ant-card-head-wrapper {
		width: 188px;
	}

	.thankyou .ant-card-head-wrapper {
		width: 160px;
	}

	.realtors .ant-card-head-wrapper {
		width: 150px;
	}
}

.custom-scrollbar::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

.custom-scrollbar::-webkit-scrollbar-track {
	background: #F2F2F2;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
	background: var(--grey-purple);
	;
	border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
	background: var(--orange);
}
</style>
